import { Button, ColorInput, Grid, Group, Text, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useMemo } from "react"

import {
  tagSchema,
  TagsRequests,
  TagsResponses,
} from "@costory/types/endpoints/tags"

const SWATCHES = [
  "#ef9a9a",
  "#f48fb1",
  "#d3a2ea",
  "#b39ddb",
  "#9fa8da",
  "#90caf9",
  "#93bfea",
  "#80cbc4",
  "#a5d6a7",
  "#c5e1a5",
  "#f8e0a1",
  "#f6cc7b",
  "#ffab91",
  "#bcaaa4",
]

type Props = {
  selectedTag?: TagsResponses.Tags
  isSaving: boolean
  onSave: (data: TagsRequests.EditTag) => void
  close: () => void
  isOpen: boolean
}

export function TagForm({ selectedTag, onSave, isSaving, close }: Props) {
  const initialValues = useMemo(() => {
    if (selectedTag) {
      return selectedTag
    }
    return {
      name: "",
      color: "",
    }
  }, [selectedTag])

  const form = useForm<TagsRequests.EditTag>({
    initialValues,
    validate: zodResolver(tagSchema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
    mode: "uncontrolled",
  })

  return (
    <form onSubmit={form.onSubmit((values) => onSave(values))}>
      <Grid gutter="md">
        <Grid.Col span={12}>
          <Grid align="center" gutter="md">
            <Grid.Col span={12}>
              <TextInput
                label="Name"
                {...form.getInputProps("name")}
                key={form.key("name")}
                placeholder="Enter a name"
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>

        <Grid.Col span={12}>
          <Grid align="center" gutter="md">
            <Grid.Col span={12}>
              <Text>Color</Text>
              <ColorInput
                format="hex"
                {...form.getInputProps("color")}
                key={form.key("color")}
                withPicker={false}
                withEyeDropper={false}
                swatchesPerRow={7}
                swatches={SWATCHES}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>

        <Grid.Col span={8}>
          <Group mt="md" gap={10}>
            <Button loading={isSaving} type="button" onClick={() => close()}>
              Cancel
            </Button>
            <Button disabled={!form.isValid()} loading={isSaving} type="submit">
              {selectedTag ? "Save" : "Create"}
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </form>
  )
}
