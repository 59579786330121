import { Group, Progress, Stack, Tooltip, Text } from "@mantine/core"
import _ from "lodash"
import { useMemo } from "react"

import { ExploreResponses } from "@costory/types/endpoints/explorer"
import { Filters } from "@costory/types/filters"

import { TrendBadge } from "@costory/front/components/TrendBadge"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useWaterfallQuery } from "@costory/front/queries/explore"
import { formatNumber } from "@costory/front/utils/format"

type KPIProps = {
  data: ExploreResponses.Waterfall["dataGroupedByValue"]
  withBreakdown: boolean
  currency: "USD" | "EUR"
}

const KPI = ({ data, withBreakdown, currency }: KPIProps) => {
  const totals = useMemo(
    () => ({
      current: _.sumBy(data, (d) => d.periodCost),
      previous: _.sumBy(data, (d) => d.previousPeriodCost),
    }),
    [data],
  )

  const breakdowns = useMemo(() => {
    if (!withBreakdown) {
      return []
    }
    return _.take(
      _.orderBy(data, (b) => b.periodCost, "desc"),
      10,
    ).map((d) => ({
      key: d.groupByValue,
      current: d.periodCost,
      previous: d.previousPeriodCost,
      ratio: d.periodCost / totals.current,
    }))
  }, [data, totals, withBreakdown])

  return (
    <Stack gap={20}>
      <Group justify="space-between" wrap="nowrap" align="center">
        <Text size="xl" fw={700}>
          {formatNumber(totals.current, "currency", 2, currency)}
        </Text>
        <TrendBadge current={totals.current} previous={totals.previous} />
      </Group>
      {breakdowns.map((breakdown) => (
        <Tooltip
          key={breakdown.key}
          label={`current: ${formatNumber(breakdown.current, "currency", 2, currency)} | previous: ${formatNumber(breakdown.previous, "currency", 2, currency)}`}
        >
          <Stack gap={8}>
            <Progress value={breakdown.ratio * 100} />
            <Group justify="space-between" wrap="nowrap">
              <Text
                style={{
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                }}
              >
                {breakdown.key}
              </Text>
              <TrendBadge
                current={breakdown.current}
                previous={breakdown.previous}
              />
            </Group>
          </Stack>
        </Tooltip>
      ))}
    </Stack>
  )
}

type Props = {
  filters: Filters
  withBreakdown?: boolean
}

export const KPIChart = ({ filters, withBreakdown = false }: Props) => {
  const waterfallQuery = useWaterfallQuery({ ...filters, limit: 4 })

  return (
    <QueryWrapper query={waterfallQuery}>
      {({ data }) => (
        <KPI
          data={data.dataGroupedByValueWithOthers}
          withBreakdown={withBreakdown}
          currency={filters.currency}
        />
      )}
    </QueryWrapper>
  )
}
