import { ActionIcon, Box, Button, Flex, Group, Pill } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import { IconPencil, IconTrash, IconZoom } from "@tabler/icons-react"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useCallback, useMemo, useState } from "react"

import { DatasourcesResponses } from "@costory/types/endpoints/datasources"
import {
  MetricRequests,
  MetricsResponses,
} from "@costory/types/endpoints/metrics"

import { DEFAULT_DATE_FORMAT } from "@costory/shared/const"
import dayjs from "@costory/shared/dayjs"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"
import { ObserveMetric } from "@costory/front/pages/Metrics/components/Observe"
import { EditCreateModalMetric } from "@costory/front/pages/Metrics/components/editCreateModal"
import { getDefaultTableOptions } from "@costory/front/utils/table"

type MetricProps = {
  metrics: MetricsResponses.CustomMetric[]
  datasources: DatasourcesResponses.Datasource[]
}

const ListMetrics = ({ metrics, datasources }: MetricProps) => {
  const [isOpen, { open, close }] = useDisclosure()
  const [zoomOpen, { open: openZoom, close: closeZoom }] = useDisclosure()
  const [selectedMetric, setSelectedMetric] =
    useState<MetricsResponses.Metric>()
  const [metricZoomId, setZoom] = useState<string>()

  const { mutateAsync: createMetrics, isPending } = useMutation({
    mutationFn: async (data: MetricRequests.EditMetric) => {
      const response = await apiClient.post("/metrics/", data)
      return response.data
    },
    onSuccess: async () => {
      // TODO: better messages
      showNotification({ color: "green", title: "Success", message: "Saved" })
      await queryClient.invalidateQueries({ queryKey: ["metrics"] })
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
  const { mutateAsync: editMetric, isPending: isPendingEdition } = useMutation({
    mutationFn: async (data: MetricRequests.EditMetric) => {
      const response = await apiClient.put(
        `/metrics/${selectedMetric!.id}`,
        data,
      )
      return response.data
    },
    onSuccess: async () => {
      // TODO: better messages
      showNotification({ color: "green", title: "Success", message: "Saved" })
      await queryClient.invalidateQueries({ queryKey: ["metrics"] })
      await queryClient.invalidateQueries({
        queryKey: ["metric", "observe", selectedMetric!.id],
      })
      await queryClient.invalidateQueries({
        queryKey: ["charts", "explorer", selectedMetric!.id],
      })
      await queryClient.invalidateQueries({
        queryKey: ["charts", "waterfall", selectedMetric!.id],
      })
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
  const { mutateAsync: deleteMetric, isPending: isPendingDeletion } =
    useMutation({
      mutationFn: async (id: string) => {
        await apiClient.delete(`/metrics/${id}`)
      },
      onSuccess: async () => {
        // TODO: better messages
        showNotification({
          color: "green",
          title: "Metric Deleted",
          message: "Deleted",
        })
        await queryClient.invalidateQueries({ queryKey: ["metrics"] })
      },
      onError: (error: { error: string }) => {
        showNotification({ color: "red", title: "Error", message: error.error })
      },
    })
  const openDeleteModal = useCallback(
    (metricToDelete: MetricsResponses.Metric) =>
      modals.openConfirmModal({
        title: "Please confirm you want to delete " + metricToDelete.name,
        labels: { confirm: "Confirm", cancel: "Cancel" },
        onConfirm: () => deleteMetric(metricToDelete.id),
      }),
    [deleteMetric],
  )
  const columns = useMemo(() => {
    const columnHelper = createMRTColumnHelper<MetricsResponses.CustomMetric>()
    return [
      columnHelper.accessor("name", {
        header: "Name",
      }),
      columnHelper.accessor("datasource.name", {
        header: "Datasource",
        Cell: ({ row }) => (
          <>
            {row.original.datasource.name}/{row.original.rawMetric}
          </>
        ),
      }),
      columnHelper.display({
        id: "owner",
        header: "Owner",
        enableColumnActions: false,
        Cell: ({ row }) => (
          <>
            {row.original.owner.firstName} {row.original.owner.lastName}
          </>
        ),
      }),
      columnHelper.accessor("tags", {
        header: "Tags",
        enableColumnFilter: false,
        enableSorting: false,
        enableHiding: false,
        Cell: ({ row }) => (
          <>
            {row.original.tags.map((elem) => (
              <>
                <Pill>{elem}</Pill>
              </>
            ))}
          </>
        ),
      }),
      columnHelper.accessor("updatedAt", {
        header: "Last Updated",
        Cell: ({ row }) => (
          <>
            {dayjs(row.original.updatedAt).local().format(DEFAULT_DATE_FORMAT)}
          </>
        ),
      }),

      columnHelper.display({
        header: "Actions",
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Group gap={8} wrap="nowrap">
            <ActionIcon
              onClick={() => {
                setZoom(row.original.id)
                openZoom()
              }}
            >
              <IconZoom />
            </ActionIcon>
            <ActionIcon
              onClick={() => {
                setSelectedMetric(row.original)
                open()
              }}
            >
              <IconPencil />
            </ActionIcon>
            <ActionIcon
              onClick={() => {
                openDeleteModal(row.original)
              }}
              loading={isPendingDeletion}
            >
              <IconTrash />
            </ActionIcon>
          </Group>
        ),
      }),
    ]
  }, [isPendingDeletion, open, openDeleteModal, openZoom])
  const table = useMantineReactTable<MetricsResponses.CustomMetric>({
    columns,
    data: metrics,
    ...getDefaultTableOptions(),
  })

  return (
    <>
      <Box>
        <Flex mb={20} justify="flex-end">
          <Button
            onClick={() => {
              setSelectedMetric(undefined)
              open()
            }}
            variant="filled"
          >
            Create New Metric
          </Button>
        </Flex>
        <EditCreateModalMetric
          datasources={datasources}
          selectedMetric={selectedMetric}
          onSave={(data: MetricRequests.EditMetric) => {
            if (selectedMetric) {
              editMetric(data)
            } else {
              createMetrics(data)
            }

            close()
          }}
          isSaving={isPending || isPendingEdition}
          close={close}
          isOpen={isOpen}
        />
        <MantineReactTable table={table} />
      </Box>
      <ObserveMetric
        selectedId={metricZoomId}
        isOpen={zoomOpen}
        onClose={closeZoom}
      />
    </>
  )
}

export const MetricsPage = () => {
  const metricQuery = useQuery({
    queryKey: ["metrics"],
    queryFn: async () => {
      const response =
        await apiClient.get<MetricsResponses.CustomMetric[]>("/metrics")
      return response.data
    },
  })
  const datasourcesQuery = useQuery({
    queryKey: ["datasources"],
    queryFn: async () => {
      const response =
        await apiClient.get<DatasourcesResponses.Datasource[]>("/datasources")
      return response.data
    },
  })

  return (
    <QueryWrapper query={metricQuery} allowEmptyArray>
      {({ data: metrics }) => (
        <QueryWrapper query={datasourcesQuery} allowEmptyArray>
          {({ data: datasources }) => (
            <ListMetrics
              metrics={metrics.filter((elem) => !("technical" in elem))}
              datasources={datasources}
            />
          )}
        </QueryWrapper>
      )}
    </QueryWrapper>
  )
}
