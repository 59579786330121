import { ActionIcon, Box, Button, Group, Pill } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconPencil, IconTrash } from "@tabler/icons-react"
import { useQuery } from "@tanstack/react-query"
import {
  MantineReactTable,
  useMantineReactTable,
  createMRTColumnHelper,
} from "mantine-react-table"
import { useMemo, useState } from "react"

import { TagsRequests, TagsResponses } from "@costory/types/endpoints/tags"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { openDeleteConfirmModal } from "@costory/front/pages/Tags/components/DeleteConfirmModal"
import { EditCreateTag } from "@costory/front/pages/Tags/components/EditCreateTag"
import {
  useCreateTagsMutation,
  useDeleteTagsMutation,
  useUpdateTagsMutation,
} from "@costory/front/queries/tags"

type TagsProps = {
  tags: TagsResponses.Tags[]
}

const ListTags = ({ tags }: TagsProps) => {
  const [isOpen, { open, close }] = useDisclosure()
  const [selectedTag, setSelectedTag] = useState<TagsResponses.Tags>()

  const { mutateAsync: createTag, isPending: isCreatingTag } =
    useCreateTagsMutation()
  const { mutateAsync: updateTag, isPending: isUpdatingTag } =
    useUpdateTagsMutation()
  const { mutateAsync: deleteTag, isPending: isDeletingTag } =
    useDeleteTagsMutation()

  const columns = useMemo(() => {
    const columnHelper = createMRTColumnHelper<TagsResponses.Tags>()
    return [
      columnHelper.accessor("name", {
        header: "Tags",
        size: 900,
        Cell: ({ row }) => (
          <Pill bg={row.original.color} radius="sm" size="md">
            {row.original.name}
          </Pill>
        ),
      }),
      columnHelper.display({
        header: "Actions",
        enableColumnActions: false,
        Cell: ({ row }) => (
          <Group gap={8}>
            <ActionIcon
              onClick={() => {
                setSelectedTag(row.original)
                open()
              }}
            >
              <IconPencil />
            </ActionIcon>
            <ActionIcon
              onClick={() => {
                openDeleteConfirmModal(row.original, deleteTag)
              }}
              color="red"
              loading={isDeletingTag}
            >
              <IconTrash />
            </ActionIcon>
          </Group>
        ),
      }),
    ]
  }, [isDeletingTag, deleteTag, open])

  const table = useMantineReactTable({
    columns,
    data: tags,
    initialState: {
      sorting: [{ id: "name", desc: false }],
    },
    renderTopToolbarCustomActions: () => (
      <Button
        onClick={() => {
          setSelectedTag(undefined)
          open()
        }}
      >
        Create New Tag
      </Button>
    ),
  })

  return (
    <Box>
      <MantineReactTable table={table} />
      <EditCreateTag
        isSaving={isCreatingTag || isUpdatingTag}
        selectedTag={selectedTag}
        onSave={(data: TagsRequests.EditTag) => {
          if (selectedTag) {
            updateTag(data)
          } else {
            createTag(data)
          }
          close()
        }}
        close={close}
        isOpen={isOpen}
      />
    </Box>
  )
}

export const TagsPage = () => {
  const TagsQuery = useQuery({
    queryKey: ["tags"],
    queryFn: async () => {
      const response = await apiClient.get<TagsResponses.Tags[]>("/tags")
      return response.data
    },
  })

  return (
    <QueryWrapper query={TagsQuery} allowEmptyArray>
      {({ data }) => <ListTags tags={data} />}
    </QueryWrapper>
  )
}
