import { GridChartBaseProps } from "@mantine/charts"
import { Group, Select, Stack } from "@mantine/core"
import _ from "lodash"

import { Filters } from "@costory/types/filters"
import { ExplorerChartType } from "@costory/types/prisma-client"

import { AreaChart } from "@costory/front/components/charts/AreaChart"
import { LineChart } from "@costory/front/components/charts/LineChart"
import { StackedBarChart } from "@costory/front/components/charts/StackedBarChart"
import { WaterfallChart } from "@costory/front/components/charts/WaterfallChart"
import { getBestDrillDown } from "@costory/front/utils/columns"

type Props = {
  filters: Filters
  height?: number
  withLegend?: boolean
  explorerChartType?: ExplorerChartType
  drillDownInto?: (groupBy: string, value: string, newGroupBy: string) => void
  setExplorerChartType?: (chartType: ExplorerChartType) => void
  referenceLines?: GridChartBaseProps["referenceLines"]
}

export const ExplorerMainChart = ({
  filters,
  height,
  explorerChartType,
  withLegend = true,
  drillDownInto = () => {},
  referenceLines,
  setExplorerChartType,
}: Props) => {
  const chartOptions = Object.values(ExplorerChartType).map((type) => ({
    value: type,
    label: _.capitalize(type),
  }))

  return (
    <Stack h="100%">
      <Group justify="flex-end">
        <Select
          w={120}
          data={chartOptions}
          value={explorerChartType}
          onChange={(value) =>
            value && setExplorerChartType?.(value as ExplorerChartType)
          }
        />
      </Group>
      {(() => {
        switch (explorerChartType) {
          case ExplorerChartType.LINE:
            return (
              <LineChart
                height={height}
                filters={filters}
                withLegend={withLegend}
              />
            )
          case ExplorerChartType.WATERFALL:
            return (
              <WaterfallChart
                height={height}
                filters={filters}
                withLegend={withLegend}
                dashboardChartConfig={75}
              />
            )
          case ExplorerChartType.AREA:
            return (
              <AreaChart
                height={height}
                filters={filters}
                withLegend={withLegend}
              />
            )
          default:
            return (
              <StackedBarChart
                height={height}
                filters={filters}
                withLegend={withLegend}
                drillDownInto={(value) =>
                  drillDownInto(
                    filters.groupBy,
                    value,
                    getBestDrillDown(filters.groupBy),
                  )
                }
                referenceLines={referenceLines}
              />
            )
        }
      })()}
    </Stack>
  )
}
