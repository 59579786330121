export const downloadBlob = (blob: Blob, filename: string) => {
  // Create a download link
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement("a")
  a.href = url
  a.download = filename // Specify the file name
  document.body.appendChild(a)
  a.click()

  // Clean up and remove the link
  document.body.removeChild(a)
  window.URL.revokeObjectURL(url)
}
