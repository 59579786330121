import { Button, Stack, Text, Title } from "@mantine/core"
import { IconBriefcaseOff } from "@tabler/icons-react"
import { Link } from "react-router-dom"

// component to show when the data neede for AlertForm is empty
export const NoVirtualDimensionsPage = () => {
  return (
    <Stack h="70vh" justify="center" align="center">
      <IconBriefcaseOff size={56} />
      <Title>No Virtual Dimension found</Title>
      <Text size="lg">You need to create virtual dimensions first</Text>
      <Button component={Link} to="/virtual-dimensions/new">
        Create virtual dimension
      </Button>
    </Stack>
  )
}
