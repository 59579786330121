import { MantineThemeOverride, MantineThemeColorsOverride } from "@mantine/core"

import { colors } from "./colors"
import { FONT_FAMILY } from "./font"

export const theme: MantineThemeOverride = {
  // Colors
  colors: colors as MantineThemeColorsOverride,
  primaryColor: "primary",
  black: colors.gray[9],

  // Font Family
  fontFamily: FONT_FAMILY,

  // Components
  components: {
    Text: {
      defaultProps: {
        size: "sm",
      },
    },

    Paper: {
      defaultProps: {
        p: 16,
        radius: 8,
        withBorder: true,
      },
    },

    ActionIcon: {
      defaultProps: {
        variant: "transparent",
      },
    },

    ThemeIcon: {
      defaultProps: {
        variant: "transparent",
      },
    },

    Loader: {
      defaultProps: {
        type: "bars",
      },
    },
  },
}
