import { Text } from "@mantine/core"
import { modals } from "@mantine/modals"

import { TagsResponses } from "@costory/types/endpoints/tags"

export const openDeleteConfirmModal = (
  row: TagsResponses.Tags,
  onConfirm: (id: string) => void,
) =>
  modals.openConfirmModal({
    title: "Please confirm your action",
    children: (
      <Text>
        Are you sure you want to delete <b>{row.name}</b>? This action cannot be
        undone.
      </Text>
    ),
    labels: { confirm: "Delete", cancel: "Cancel" },
    confirmProps: { color: "red" },
    onConfirm: () => onConfirm(row.id),
  })
