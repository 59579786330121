import { Flex, Paper, useMantineColorScheme } from "@mantine/core"

import { optionType } from "@costory/front/components/QueryBuilder"

export const SelectedValueRenderer = (selected: optionType[]) => {
  const { colorScheme } = useMantineColorScheme()
  return selected.length ? (
    <Flex
      justify="flex-start"
      style={{
        overflowX: "auto",
        scrollbarWidth: "none",
      }}
    >
      {selected.map(
        ({ label }, index) =>
          label && (
            <Paper
              key={index}
              bg={`${colorScheme === "light" ? "gray.2" : "dark"}`}
              px={7}
              py={0}
              mr={8}
              fz={15}
              radius="sm"
              style={{
                fontWeight: 400,
                display: "inline-block",
              }}
            >
              {label}
            </Paper>
          ),
      )}
    </Flex>
  ) : null
}
