import { Button, Stack } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"
import { FC } from "react"
import { Link } from "react-router-dom"

import { AlertsResponses } from "@costory/types/endpoints/alerts"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import AlertTable from "@costory/front/pages/Alerts/AlertsTable"
import { NoAlertsPage } from "@costory/front/pages/Alerts/NoAlertPage"
import { useGetAllAlertsQuery } from "@costory/front/queries/alerts"

type _Props = {
  alerts: AlertsResponses.GetAllAlerts
}

const _AlertsListPage: FC<_Props> = ({ alerts }) => {
  if (alerts.length === 0) {
    return <NoAlertsPage />
  }
  return (
    <Stack>
      <Button
        component={Link}
        to="new"
        leftSection={<IconPlus />}
        styles={{ root: { alignSelf: "flex-end" } }}
      >
        Create a new alert
      </Button>
      <AlertTable alertData={alerts} />
    </Stack>
  )
}

export const AlertsListPage = () => {
  const alertsQuery = useGetAllAlertsQuery()

  return (
    <QueryWrapper query={alertsQuery} allowEmptyArray>
      {({ data: alerts }) => <_AlertsListPage alerts={alerts} />}
    </QueryWrapper>
  )
}
