import { Badge } from "@mantine/core"
import { useMemo } from "react"

import { ComputeStatus } from "@costory/types/endpoints/virtualDimensions"

const VIRTUAL_DIMENSION_STATUSES = {
  DRAFT: { label: "Draft", color: "gray" },
  COMPLETED: { label: "Completed", color: "green" },
}

type Props = {
  status: keyof typeof VIRTUAL_DIMENSION_STATUSES
}

export const VirtualDimensionStatusCell = ({ status }: Props) => {
  const badgeProps = useMemo(() => VIRTUAL_DIMENSION_STATUSES[status], [status])
  return <Badge color={badgeProps.color}>{badgeProps.label}</Badge>
}

const VIRTUAL_DIMENSION_COMPUTE_STATUSES: Record<
  ComputeStatus,
  { label: string; color: string }
> = {
  DRAFT: { label: "Draft", color: "gray" },
  TO_REFRESH: { label: "Require Refresh", color: "red" },
  LIVE: { label: "Live", color: "green" },
  REFRESHING: { label: "Refresh in progress", color: "yellow" },
}

type PropsCompute = {
  status: ComputeStatus
}

export const VirtualDimensionComputeStatusCell = ({ status }: PropsCompute) => {
  const badgeProps = useMemo(
    () => VIRTUAL_DIMENSION_COMPUTE_STATUSES[status],
    [status],
  )
  return <Badge color={badgeProps.color}>{badgeProps.label}</Badge>
}
