import { Button, Stack } from "@mantine/core"
import { IconTablePlus } from "@tabler/icons-react"
import { Link } from "react-router-dom"

import { BudgetsResponses } from "@costory/types/endpoints/budgets"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { BudgetsListTable } from "@costory/front/pages/Budgets/BudgetsListTable"
import { EmptyBudgetsListPage } from "@costory/front/pages/Budgets/EmptyBudgetsListPage"
import { useBudgetsListQuery } from "@costory/front/queries/budgets"
import { PropsWithData } from "@costory/front/utils/propsWithData"

type BudgetsListPageProps = PropsWithData<BudgetsResponses.GetAllBudgets>

const _BudgetsListPage = ({ data }: BudgetsListPageProps) => {
  if (data.length === 0) {
    return <EmptyBudgetsListPage />
  }
  return (
    <Stack>
      <Button
        component={Link}
        to="new"
        leftSection={<IconTablePlus />}
        styles={{ root: { alignSelf: "flex-end" } }}
      >
        Create a new Budget
      </Button>
      <BudgetsListTable budgets={data} />
    </Stack>
  )
}

export const BudgetsListPage = () => (
  <QueryWrapper query={useBudgetsListQuery()} allowEmptyArray>
    {({ data }) => <_BudgetsListPage data={data} />}
  </QueryWrapper>
)
