import { Button, Stack, Text, Title } from "@mantine/core"
import { IconTableOff, IconTablePlus } from "@tabler/icons-react"
import { Link } from "react-router-dom"

export const EmptyBudgetsListPage = () => (
  <Stack align="center" pt="10vh">
    <IconTableOff size="10vh" />
    <Title>There are no budgets yet.</Title>
    <Text>Create a new budget to get started.</Text>
    <Button component={Link} to="new" leftSection={<IconTablePlus />}>
      Create new Budget
    </Button>
  </Stack>
)
