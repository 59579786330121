import { dark } from "@clerk/themes"
import { Appearance } from "@clerk/types"

import { colors } from "./colors"
import { FONT_FAMILY } from "./font"

export const CLERK_THEMES: { light: Appearance; dark: Appearance } = {
  light: {
    variables: {
      colorPrimary: colors.primary[6],
      colorDanger: colors.red[6],
      colorSuccess: colors.green[6],
      colorText: colors.gray[9],
      colorTextSecondary: colors.green[7],
      fontFamily: FONT_FAMILY,
      fontWeight: {
        normal: 400,
        medium: 600,
        bold: 800,
      },
    },
  },
  dark: {
    baseTheme: dark,
    variables: {
      colorBackground: colors.gray[7],
      fontWeight: {
        normal: 400,
        medium: 600,
        bold: 800,
      },
    },
  },
}
