import { Accordion, ActionIcon, Center, Menu } from "@mantine/core"
import {
  IconCopy,
  IconDotsVertical,
  IconPencil,
  IconTrash,
} from "@tabler/icons-react"
import { ReactNode } from "react"

type Props = {
  children: ReactNode
  onRename: () => void
  onDuplicate: () => void
  onDelete: () => void
}

export const RuleEditorAccordionControl = ({
  children,
  onRename,
  onDuplicate,
  onDelete,
}: Props) => {
  return (
    <Center>
      <Accordion.Control p={0}>{children}</Accordion.Control>
      <Menu>
        <Menu.Target>
          <ActionIcon size="sm" color="gray.3">
            <IconDotsVertical />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item leftSection={<IconPencil size={16} />} onClick={onRename}>
            Rename rule
          </Menu.Item>
          <Menu.Item leftSection={<IconCopy size={16} />} onClick={onDuplicate}>
            Duplicate rule
          </Menu.Item>
          <Menu.Item
            leftSection={<IconTrash size={16} />}
            color="red"
            onClick={onDelete}
          >
            Delete rule
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Center>
  )
}
