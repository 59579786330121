import { Button, TextInput } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import {
  ActionWithRulesAndAddersProps,
  QueryBuilder as BaseQueryBuilder,
  ControlElementsProp,
  FieldSelectorProps,
  FullField,
  OperatorSelectorProps,
  RuleGroupType,
  RuleType,
  ValueEditorProps,
} from "react-querybuilder"

import { AxesResponses } from "@costory/types/endpoints/axes"

import { queryValidator } from "@costory/shared/utils/reactQueryBuilder"

import SmartQueryBuilderModal from "./SmartQueryBuilderModal"
import type { SearchAcrossValuesContext } from "./types"

interface Props {
  controlElements: ControlElementsProp<FullField, string>
  defaultQuery?: RuleGroupType
  fields: AxesResponses.Axis[]
  onQueryChange: (query: RuleGroupType | null) => void
}

const HardcodedFieldSelector = (props: FieldSelectorProps) => {
  const { value } = props
  return <TextInput value={value} style={{ flexGrow: "1" }} readOnly />
}

const HardcodedOperatorSelector = (props: OperatorSelectorProps) => {
  const { value } = props
  return <span>{value}</span>
}

const HardcodedValueEditor = (props: ValueEditorProps) => {
  const { value } = props
  return <TextInput value={value} style={{ flexGrow: "1" }} readOnly />
}

const AddRuleButton = (props: ActionWithRulesAndAddersProps) => {
  const { handleOnClick } = props

  const [opened, { open: openModal, close: closeModal }] = useDisclosure(false)

  const onSubmit = (
    e: React.MouseEvent<HTMLButtonElement>,
    context: SearchAcrossValuesContext,
  ) => {
    handleOnClick(e, context)
    closeModal()
  }

  return (
    <>
      <SmartQueryBuilderModal
        opened={opened}
        onClose={closeModal}
        onSubmit={onSubmit}
      />
      <Button onClick={openModal}>+ Rule</Button>
    </>
  )
}

const onAddRule = (
  _rule: unknown,
  _parentPath: unknown,
  _query: unknown,
  context: SearchAcrossValuesContext,
) => {
  const rule: RuleType<string, string, string> = {
    field: context.field,
    operator: "=",
    value: context.value,
  }
  return rule
}

const SmartQueryBuilder = ({
  controlElements,
  defaultQuery,
  fields,
  onQueryChange,
}: Props) => {
  return (
    <BaseQueryBuilder
      fields={fields}
      controlClassnames={{ rule: "base-query-builder-rule" }}
      defaultQuery={defaultQuery}
      onQueryChange={onQueryChange}
      getDefaultField="cos_provider"
      validator={(val) => queryValidator(val, [], [])}
      onAddRule={onAddRule}
      controlElements={{
        ...controlElements,
        addGroupAction: null,
        addRuleAction: AddRuleButton,
        operatorSelector: HardcodedOperatorSelector,
        fieldSelector: HardcodedFieldSelector,
        valueEditor: HardcodedValueEditor,
      }}
    />
  )
}

export default SmartQueryBuilder
