import {
  ActionIcon,
  Badge,
  Button,
  Checkbox,
  Group,
  Menu,
  Popover,
  Stack,
  TagsInput,
  Title,
  Tree,
} from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { useForm, UseFormReturnType } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import {
  IconAdjustmentsAlt,
  IconChevronDown,
  IconSignRightFilled,
  IconTrash,
  IconTrashX,
} from "@tabler/icons-react"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import { useState } from "react"

import { AxesResponses } from "@costory/types/endpoints/axes"
import {
  DigestResponses,
  DigestRequests,
} from "@costory/types/endpoints/digest"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { ButtonAddTo } from "@costory/front/pages/Digest/ButtonAddToDigest"
import { ExplorerDrawer } from "@costory/front/pages/Digest/Drawer"
import {
  addChild,
  addContext,
  convertToTree,
  deleteAllChilds,
  deleteChild,
  Form,
  formatMarkdown,
  formatSlice,
  getOptions,
  getquery,
  NodeInvestigation,
  saveTagsNodes,
  TreeData,
} from "@costory/front/pages/Digest/helper"
import { useAxesQuery } from "@costory/front/queries/axes"

export const VdimHelperPage = () => {
  const axesQuery = useAxesQuery()
  const form = useForm<Form>({
    mode: "uncontrolled",
    initialValues: { nodes: [], labels: [] },
  })
  const json = JSON.stringify(form.getValues())
  const downloadFile = () => {
    // create file in browser
    const fileName = "my-file"
    const blob = new Blob([json], { type: "application/json" })
    const href = URL.createObjectURL(blob)

    // create "a" HTLM element with href to file
    const link = document.createElement("a")
    link.href = href
    link.download = fileName + ".json"
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }
  return (
    <QueryWrapper query={axesQuery}>
      {(axes) => (
        <>
          <Stack>
            <Title>Digest Builder (Pre-Alpha): </Title>
            <Stack align="flex-start">
              <Group>
                <Button onClick={() => form.reset()}> Reset Digest </Button>
                <Button onClick={() => downloadFile()}>
                  {" "}
                  Save Digest in JSON
                </Button>
              </Group>
              <_DigestPage period="Absolute" axes={axes.data} form={form} />
            </Stack>
          </Stack>
        </>
      )}
    </QueryWrapper>
  )
}

function _DigestPage({
  axes,
  period,
  form,
}: {
  axes: AxesResponses.Axis[]
  form: UseFormReturnType<Form>
  period: DigestRequests.Period
}) {
  const query = useQuery({
    queryKey: ["digest", period],
    queryFn: async () => {
      const response = await apiClient.get<DigestResponses.Investigation>(
        `/digest/${period}`,
      )
      return response.data
    },
  })

  const mapper = new Map<string, string>()
  axes.forEach((elem) => mapper.set(elem.name, elem.label))
  const [opened, handlers] = useDisclosure(false)
  const [position, setPosition] = useState<{ key: string; value: string }[]>([])
  const [checkedNodes, setCheckedNodes] = useState<Map<string, boolean>>(
    new Map(),
  )
  return (
    <QueryWrapper query={query}>
      {(data) => (
        <>
          <ExplorerDrawer
            period={period}
            opened={opened}
            close={handlers.close}
            position={position}
          />
          <Group justify="flex-end" align="end">
            <DatePickerInput
              type="range"
              disabled
              label="Period"
              placeholder="Pick dates range"
              value={[new Date(data.data[0].ffrom), new Date(data.data[0].tto)]}
            />
            <Button
              onClick={() =>
                navigator.clipboard.writeText(
                  formatMarkdown(form, mapper, data.data[0].ffrom).join("\n"),
                )
              }
            >
              Copy report as Markdown
            </Button>
          </Group>

          <ButtonAddTo
            disabled={false}
            mapper={mapper}
            options={getOptions(data.data, [])}
            addChild={(direction) => {
              const baseNode = {
                uuid: Math.random().toString(),
                parent: undefined,
                isOther: false,
                direction: "View Per Provider",
                position: [],
                displayOptions: {
                  text: "",
                  displayContext: true,
                  order: "asc",
                  orderByColumn: "absChange",
                },
                data: { diff: 0 },
              } as NodeInvestigation
              form.insertListItem("nodes", baseNode)
              addChild(form, direction, data.data, baseNode.uuid)
            }}
          />
          <Tree
            data={convertToTree(form.getValues().nodes)}
            renderNode={({
              node,
              expanded,
              hasChildren,
              elementProps,
              tree,
            }) => {
              const nodeParsed = node.nodeProps as NodeInvestigation
              const uuidNodesChecked = Array.from(checkedNodes.keys()).filter(
                (key) => checkedNodes.get(key),
              )
              return (
                <Group
                  gap={5}
                  style={elementProps.style}
                  className={elementProps.className}
                >
                  <Checkbox.Indicator
                    checked={checkedNodes.get(nodeParsed.uuid) || false}
                    onClick={() =>
                      setCheckedNodes(
                        new Map(checkedNodes).set(
                          nodeParsed.uuid,
                          !(checkedNodes.get(nodeParsed.uuid) || false),
                        ),
                      )
                    }
                  />
                  {formatSlice(
                    nodeParsed,
                    nodeParsed.direction,
                    mapper,
                    true,
                    true,
                  )}
                  <Menu shadow="md" width={200}>
                    <Menu.Target>
                      <ActionIcon
                        variant="transparent"
                        aria-label="Settings"
                        disabled={nodeParsed.isOther}
                      >
                        <IconAdjustmentsAlt
                          style={{ width: "70%", height: "70%" }}
                          stroke={1.5}
                        />
                      </ActionIcon>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Label>Explore</Menu.Label>
                      <Menu.Item
                        disabled={nodeParsed.position.length == 0}
                        onClick={() => {
                          setPosition(nodeParsed.position)
                          handlers.open()
                        }}
                      >
                        See in Explorer Drawer
                      </Menu.Item>
                      <Menu.Item
                        disabled={node.nodeProps?.position.length == 0}
                        onClick={() =>
                          window.open(
                            `/explorer?${getquery(node as TreeData).queryFormatted}`,
                            "_blank",
                          )
                        }
                      >
                        See in Explorer
                      </Menu.Item>
                      <Menu.Label>Contextualize</Menu.Label>
                      <Menu.Item
                        onClick={() =>
                          addContext(form, nodeParsed.uuid, {
                            displayContext:
                              !nodeParsed.displayOptions.displayContext,
                          })
                        }
                      >
                        Adds previous and current absolute value.
                      </Menu.Item>
                      <Menu.Item
                        onClick={() =>
                          addContext(form, nodeParsed.uuid, {
                            orderByColumn: "cost",
                            order: "desc",
                          })
                        }
                      >
                        Order by absolute cost desc
                      </Menu.Item>
                      <Menu.Item
                        onClick={() =>
                          addContext(form, nodeParsed.uuid, {
                            orderByColumn: "absChange",
                            order: "desc",
                          })
                        }
                      >
                        Order by absolute change desc
                      </Menu.Item>
                      <Menu.Item
                        onClick={() =>
                          addContext(form, nodeParsed.uuid, {
                            orderByColumn: "relativeChange",
                            order: "desc",
                          })
                        }
                      >
                        Order by relative change desc
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                  <ActionIcon
                    variant="transparent"
                    aria-label="Settings"
                    onClick={() => deleteChild(nodeParsed.uuid, form)}
                    disabled={nodeParsed.isOther}
                  >
                    <IconTrash
                      style={{ width: "70%", height: "70%" }}
                      stroke={1.5}
                    />
                  </ActionIcon>

                  {checkedNodes.get(nodeParsed.uuid) && (
                    <TagSelector
                      form={form}
                      checkedUuid={uuidNodesChecked}
                      cleanSelected={() => setCheckedNodes(new Map())}
                    />
                  )}

                  <ButtonAddTo
                    disabled={nodeParsed.position.length === 0}
                    total={nodeParsed.data.diff}
                    mapper={mapper}
                    options={getOptions(data.data, nodeParsed.position)}
                    addChild={(direction) =>
                      addChild(form, direction, data.data, nodeParsed.uuid)
                    }
                  />
                  {hasChildren && (
                    <>
                      <IconChevronDown
                        onClick={() => tree.toggleExpanded(node.value)}
                        size={18}
                        style={{
                          transform: expanded
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                      <ActionIcon
                        onClick={() => deleteAllChilds(nodeParsed.uuid, form)}
                      >
                        <IconTrashX
                          style={{ width: "70%", height: "70%" }}
                          stroke={1.5}
                        />
                      </ActionIcon>
                    </>
                  )}
                  {nodeParsed.labels?.map((el) => (
                    <Badge
                      key={el}
                      color={
                        COLORS[
                          el
                            .split("")
                            .reduce(
                              (acc, char) => acc + char.charCodeAt(0),
                              0,
                            ) % COLORS.length
                        ]
                      }
                    >
                      {el}
                    </Badge>
                  ))}
                </Group>
              )
            }}
          />
        </>
      )}
    </QueryWrapper>
  )
}

function TagSelector({
  checkedUuid,
  form,
  cleanSelected,
}: {
  checkedUuid: string[]
  form: UseFormReturnType<Form>
  cleanSelected: () => void
}) {
  const [value, setValue] = useState<string[]>([])
  const [opened, setOpened] = useState(false)
  return (
    <Popover
      width={300}
      trapFocus
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
      onChange={setOpened}
      closeOnClickOutside={false}
    >
      <Popover.Target>
        <ActionIcon onClick={() => setOpened((o) => !o)}>
          <IconSignRightFilled
            style={{ width: "70%", height: "70%" }}
            stroke={1.5}
          />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <TagsInput
            label="Press Enter to submit a tag"
            placeholder="Enter tag"
            value={value}
            onChange={setValue}
            data={_.uniq(
              form
                .getValues()
                .nodes.map((el) => el.labels)
                .filter((el) => !!el)
                .flat(),
            )}
          />
          <Button
            onClick={() => {
              saveTagsNodes(value, checkedUuid, form)
              setOpened(false)
              cleanSelected()
            }}
          >
            Apply
          </Button>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}

const COLORS = [
  "red.6",
  "yellow.5",
  "orange.5",
  "green.5",
  "blue.5",
  "indigo.5",
  "lime.5",
  "cyan.5",
]
