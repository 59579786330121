import { TextInput, Text, Group, Button, Grid, Checkbox } from "@mantine/core"
import { useForm } from "@mantine/form"
import { useMemo } from "react"

import { LabelsExplorerResponses } from "@costory/types/endpoints/labelsExplorer"

import { LabelsExplorerValuesTable } from "@costory/front/pages/LabelsExplorer/labelsExplorerValuesTable"

type Props = {
  labelExplorerData?: LabelsExplorerResponses.LabelsExplorer
  onSave: (data: LabelsExplorerResponses.LabelsExplorer) => void
  onSaving: boolean
}

export function LabelsExplorerFrom({
  labelExplorerData,
  onSave,
  onSaving,
}: Props) {
  const initialValues = useMemo(() => {
    if (labelExplorerData) {
      return labelExplorerData
    }
    return {
      id: "",
      key: "",
      numberValues: "",
      description: "",
      totalCostKeyPresentTotal: "",
      values: [
        {
          value: "",
          proportionCostAllocated: 0,
        },
        {
          value: "",
          proportionCostAllocated: 0,
        },
      ],
      ingested: false,
    }
  }, [labelExplorerData])

  const form = useForm<LabelsExplorerResponses.LabelsExplorer>({
    initialValues,
    validateInputOnBlur: true,
    validateInputOnChange: true,
  })

  return (
    <form onSubmit={form.onSubmit(onSave)}>
      <Grid gutter="md">
        <Grid.Col span={12}>
          <Grid gutter="md">
            <Grid.Col span={10}>
              <Text>Label Explorer Form</Text>
            </Grid.Col>
            <Grid.Col span={2}>
              <Checkbox
                label="Ingested"
                key={form.key("ingested")}
                {...form.getInputProps("ingested", { type: "checkbox" })}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12}>
          <Grid gutter="md">
            <Grid.Col>
              <TextInput
                label={form.getValues().key}
                {...form.getInputProps("label")}
              />
            </Grid.Col>
            <Grid.Col>
              <TextInput
                label="Description"
                withAsterisk
                {...form.getInputProps("description")}
              />
            </Grid.Col>
            <Grid.Col mt={12}>
              <LabelsExplorerValuesTable
                lableValues={form.getValues().values}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Group justify="flex-end" mt="md">
        <Button loading={onSaving} type="submit">
          Submit
        </Button>
      </Group>
    </form>
  )
}
