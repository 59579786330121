import { LineChart } from "@mantine/charts"
import { Modal } from "@mantine/core"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"

import { MetricsResponses } from "@costory/types/endpoints/metrics"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"

type Props = {
  isOpen: boolean
  onClose: () => void
  data: MetricsResponses.Observe
}
export const _ObserveMetric = ({ isOpen, data, onClose }: Props) => {
  return (
    <Modal
      size="xl"
      opened={isOpen}
      onClose={onClose}
      title={`Observe ${data.name}`}
    >
      <LineChart
        h={300}
        data={_.sortBy(
          data.result.map((elem) => ({
            date: elem.usage_start_day,
            val: elem.business_metric,
          })),
          (d) => d.date,
        )}
        dataKey="date"
        series={[{ name: "val", color: "red.6" }]}
      />
    </Modal>
  )
}

export const ObserveMetric = ({
  selectedId,
  isOpen,
  onClose,
}: {
  selectedId?: string
  isOpen: boolean
  onClose: () => void
}) => {
  const query = useQuery({
    queryKey: ["metric", "observe", selectedId],
    queryFn: async () => {
      const response = await apiClient.get<MetricsResponses.Observe>(
        `/metrics/observe/${selectedId}`,
      )
      return response.data
    },
    enabled: selectedId !== undefined,
  })
  if (!selectedId) {
    return <></>
  }

  return (
    <QueryWrapper query={query} allowEmptyArray>
      {({ data }) => (
        <_ObserveMetric isOpen={isOpen} data={data} onClose={onClose} />
      )}
    </QueryWrapper>
  )
}
