import { VirtualDimensionForm } from "@costory/front/pages/VirtualDimensions/components/VirtualDimensionForm"
import {
  useCreateDraftVirtualDimensionMutation,
  useCreateVirtualDimensionMutation,
} from "@costory/front/queries/virtualDimensions"

export const NewVirtualDimensionPage = () => {
  const { mutateAsync: createVirtualDimension, isPending } =
    useCreateVirtualDimensionMutation()
  const {
    mutateAsync: createDraftVirtualDimension,
    isPending: isPendingDraftVirtualDimensions,
  } = useCreateDraftVirtualDimensionMutation()

  return (
    <VirtualDimensionForm
      onSave={createVirtualDimension}
      isSaving={isPending}
      isSavingDraft={isPendingDraftVirtualDimensions}
      onSaveDraft={createDraftVirtualDimension}
    />
  )
}
