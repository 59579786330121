import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"

import { LabelsExplorerResponses } from "@costory/types/endpoints/labelsExplorer"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export const useLabelsExplorerQuery = () => {
  return useQuery({
    queryKey: ["labels-explorer"],
    queryFn: async () => {
      const response =
        await apiClient.get<LabelsExplorerResponses.LabelsExplorer[]>(
          "/labels-explorer",
        )
      return response.data
    },
  })
}

export const useLabelsExplorerMutation = () => {
  return useMutation({
    mutationFn: async (data: LabelsExplorerResponses.LabelsExplorer) => {
      const response = await apiClient.post(`/labels-explorer/${data.id}`, data)
      return response.data
    },
    onSuccess: async ({ name }) => {
      await queryClient.invalidateQueries({ queryKey: ["labels-explorer"] })
      showNotification({
        color: "green",
        title: "Label updated!",
        message: `Label ${name} updated successfully!`,
      })
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
}
