import { Button, Group, Modal, Paper, Stack, Title } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { Responsive, WidthProvider } from "react-grid-layout"

import { TreeNodeType } from "@costory/types/endpoints/folders"

import { FileExplorer } from "@costory/front/components/FileExplorer/FileExplorer"
import { DashboardWidget } from "@costory/front/components/dashboard/DashboardWidget"
import { CHART_TYPE_WIDGET_CONFIG } from "@costory/front/components/dashboard/constants"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { NoDefaultDashboard } from "@costory/front/pages/Home/NoDefaultDashboard"
import {
  useDefaultDashboardQuery,
  useSetDefaultDashboardMutation,
} from "@costory/front/queries/dashboard"
import { useListSavedViewsQuery } from "@costory/front/queries/savedViews"

const ResponsiveReactGridLayout = WidthProvider(Responsive)

export const HomePage = () => {
  const savedViewQuery = useListSavedViewsQuery()
  const dashboardQuery = useDefaultDashboardQuery()
  const {
    mutateAsync: setDefaultDashboard,
    isPending: isSetDefaultDashboardMutationPending,
  } = useSetDefaultDashboardMutation()

  const [
    isDefaultDashboardPickerModalOpen,
    {
      open: openDefaultDashboardPickerModal,
      close: closeDefaultDashboardPickerModal,
    },
  ] = useDisclosure()

  const handleOpenDashboard = async (dashboardId: string) => {
    await setDefaultDashboard(dashboardId)
    closeDefaultDashboardPickerModal()
  }

  return (
    <QueryWrapper query={dashboardQuery} allowNull>
      {({ data: dashboard }) => {
        if (!dashboard) {
          return <NoDefaultDashboard />
        }

        const widgets = dashboard.dashboardWidgets!.map((widget, index) => ({
          i: `${index + 1}`,
          ...widget,
          ...CHART_TYPE_WIDGET_CONFIG[widget.chartType].config,
        }))

        return (
          <>
            <Group justify="flex-end">
              <Button onClick={openDefaultDashboardPickerModal}>
                Set default dashboard
              </Button>
            </Group>

            <Modal
              opened={isDefaultDashboardPickerModalOpen}
              onClose={closeDefaultDashboardPickerModal}
              title={<Title>Select a default dashboard</Title>}
              size="70vw"
              styles={{ body: { height: "70vh" } }}
            >
              <FileExplorer
                onDoubleClick={(id, type) => {
                  if (type !== TreeNodeType.Dashboard) {
                    return
                  }
                  handleOpenDashboard(id)
                }}
                onOpenDashboard={handleOpenDashboard}
                defaultShouldShowSavedViews={false}
                buttonProps={{
                  label: "Set as default",
                  loading: isSetDefaultDashboardMutationPending,
                }}
              />
            </Modal>
            <QueryWrapper query={savedViewQuery} allowEmptyArray>
              {({ data: savedViews }) => (
                <Stack>
                  <Title>{dashboard.name}</Title>
                  <ResponsiveReactGridLayout
                    isResizable={false}
                    preventCollision={false}
                    verticalCompact
                    margin={[20, 20]}
                    containerPadding={[0, 0]}
                    draggableHandle=".dragHandle"
                    cols={{ lg: 8, md: 8, sm: 8, xs: 8, xxs: 8 }}
                    autoSize
                  >
                    {widgets.map((widget) => {
                      const view = savedViews.find(
                        (view) => view.id === widget.viewId,
                      )
                      if (!view) return null
                      return (
                        <Paper
                          p={0}
                          key={widget.i}
                          data-grid={{
                            x: widget.x,
                            y: widget.y,
                            w: widget.w,
                            h: widget.h,
                            i: widget.i,
                            ...CHART_TYPE_WIDGET_CONFIG[widget.chartType]
                              .config,
                          }}
                        >
                          <DashboardWidget
                            title={widget.title}
                            height={widget.h * 160}
                            savedView={view}
                            chartType={widget.chartType}
                          />
                        </Paper>
                      )
                    })}
                  </ResponsiveReactGridLayout>
                </Stack>
              )}
            </QueryWrapper>
          </>
        )
      }}
    </QueryWrapper>
  )
}
