export type FocusCols =
  | "cos_service_name"
  | "cos_sku"
  | "cos_sub_account_id"
  | string

export function getBestDrillDown(currentGroupBy: FocusCols): FocusCols {
  switch (currentGroupBy) {
    case "cos_service_name": {
      return "cos_sku"
    }
    case "cos_sub_account_id": {
      return "cos_service_name"
    }
    default: {
      return "cos_sub_account_id"
    }
  }
}
