import { useQuery } from "@tanstack/react-query"

import {
  ExploreRequests,
  ExploreResponses,
} from "@costory/types/endpoints/explorer"
import {
  VirtualDimensionsRequests,
  VirtualDimensionsResponses,
} from "@costory/types/endpoints/virtualDimensions"
import { Filters } from "@costory/types/filters"

import { apiClient } from "@costory/front/lib/apiClient"

export const useExplorerQuery = (filters: Filters) => {
  return useQuery({
    queryKey: ["charts", "explorer", filters.metricId, filters],
    queryFn: async () => {
      const response = await apiClient.post<ExploreResponses.ExplorerData>(
        "/explore/explorer",
        filters,
      )
      return response.data
    },
  })
}

export const useWaterfallQuery = (filters: Filters) => {
  return useQuery({
    queryKey: ["charts", "waterfall", filters.metricId, filters],
    queryFn: async () => {
      const response = await apiClient.post<
        ExploreRequests.WaterfallData,
        { data: ExploreResponses.Waterfall }
      >("/explore/waterfall", filters)
      return response.data
    },
  })
}

export const useExplorerDrawer = (
  data: VirtualDimensionsRequests.SheetExplorerData,
) => {
  return useQuery({
    queryKey: ["virtual-dimensions", "prefill", data],
    queryFn: async () => {
      const response = await apiClient.post<
        VirtualDimensionsRequests.SheetExplorerData,
        { data: VirtualDimensionsResponses.SheetExplorerData }
      >("/virtual-dimensions/explore-cost", data)
      return response.data
    },
  })
}
