import { Modal } from "@mantine/core"

import { LabelsExplorerResponses } from "@costory/types/endpoints/labelsExplorer"

import { LabelsExplorerFrom } from "@costory/front/pages/LabelsExplorer/labelsExplorerFrom"

type Props = {
  data?: LabelsExplorerResponses.LabelsExplorer
  isOpen: boolean
  onClose: () => void
  onSave: (data: LabelsExplorerResponses.LabelsExplorer) => void
  onSaving: boolean
}

export const LabelsExplorerModal = ({
  data,
  isOpen,
  onClose,
  onSave,
  onSaving,
}: Props) => {
  return (
    <Modal size="xl" opened={isOpen} onClose={onClose}>
      <LabelsExplorerFrom
        labelExplorerData={data}
        onSave={onSave}
        onSaving={onSaving}
      />
    </Modal>
  )
}
