import { FormErrors } from "@mantine/form"
import _ from "lodash"

function prettyShowPath(path: string) {
  if (path.startsWith("leftoverRule")) {
    return "Leftover"
  }
  if (path.startsWith("rules")) {
    return `Rule ${path.split(".")[1]}`
  }
}

export function showError({
  prefix = undefined,
  errors,
}: {
  prefix?: string
  errors: FormErrors
}) {
  if (!prefix) {
    return _.uniq(
      Object.entries(errors).map((elem) => `${prettyShowPath(elem[0])}`),
    )
  }
  const output = Object.entries(errors)
    .filter((key) => key[0].startsWith(prefix))
    .map((elem) => elem[1])
  return output
}
