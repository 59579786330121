import { Box, Group, Tooltip } from "@mantine/core"

import { InsightsResponses } from "@costory/types/endpoints/insights"

import { NumberCell } from "@costory/front/components/table/NumberCell"

export function InsightsCustomTooltip({
  isVsTwoMonths,
  data,
  children,
}: {
  isVsTwoMonths: boolean
  data: InsightsResponses.Insight
  children: React.ReactNode
}) {
  const {
    baseline,
    diffLastTwoMonths,
    diffLastSixMonths,
    relativeTwoMonths,
    relativeSixMonths,
  } = data
  return (
    <Tooltip
      label={
        <Box>
          <Group gap="xs">
            Last Month Cost:
            <NumberCell numberType="currency" value={baseline} currency="USD" />
          </Group>
          <Box>
            {isVsTwoMonths ? (
              <Group gap="xs" mb="md">
                Avg cost for previous 2 months:
                <NumberCell
                  numberType="currency"
                  value={baseline - diffLastTwoMonths}
                  currency="USD"
                />
              </Group>
            ) : (
              <Group gap="xs" mb="md">
                Avg cost for previous 6 months:
                <NumberCell
                  numberType="currency"
                  value={baseline - diffLastSixMonths}
                  currency="USD"
                />
              </Group>
            )}
            <Group gap="xs">
              Monthly change:
              <NumberCell
                numberType="currency"
                value={isVsTwoMonths ? diffLastTwoMonths : diffLastSixMonths}
                currency="USD"
              />
            </Group>
            <Group gap="xs">
              Percentage change:
              <NumberCell
                numberType="percent"
                value={isVsTwoMonths ? relativeTwoMonths : relativeSixMonths}
                currency="USD"
              />
            </Group>
          </Box>
        </Box>
      }
    >
      {children}
    </Tooltip>
  )
}
