import { Textarea } from "@mantine/core"
import { useState } from "react"
import { RuleGroupType, formatQuery } from "react-querybuilder"
import { parseCEL } from "react-querybuilder/dist/parseCEL"

const CELQueryBuilder = ({
  onQueryChange,
  queryError,
  initialQuery,
}: {
  onQueryChange: (query: RuleGroupType | null) => void
  queryError: boolean
  initialQuery?: RuleGroupType
}) => {
  const [rawQuery, setRawQuery] = useState<string>(
    initialQuery ? formatQuery(initialQuery, "cel") : "",
  )

  return (
    <Textarea
      onChange={(e) => {
        const value = e.target.value
        setRawQuery(value)
        if (value) {
          onQueryChange(parseCEL(value))
        }
      }}
      error={rawQuery && queryError && "Invalid CEL query"}
    />
  )
}

export default CELQueryBuilder
