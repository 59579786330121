import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosResponse } from "axios"

import {
  AlertsRequests,
  AlertsResponses,
} from "@costory/types/endpoints/alerts"
import { ExploreResponses } from "@costory/types/endpoints/explorer"
import { Filters } from "@costory/types/filters"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export const useValueSuggestionQuery = (filters: Filters | undefined) => {
  return useQuery({
    queryKey: ["charts", "explorer", filters?.metricId, filters],
    queryFn: async () => {
      if (!filters) {
        return null
      }

      const response = await apiClient.post<ExploreResponses.ExplorerData>(
        "/explore/explorer",
        filters,
      )
      return response.data
    },
  })
}

export const useGetAllAlertsQuery = () => {
  return useQuery({
    queryKey: ["alerts"],
    queryFn: async () => {
      const response =
        await apiClient.get<AlertsResponses.GetAllAlerts>("/alerts")
      return response.data
    },
  })
}

export const useGetAlertQuery = (id: string) => {
  return useQuery({
    queryKey: ["alerts", { id }],
    queryFn: async () => {
      const response = await apiClient.get<AlertsResponses.Alert>(
        `/alerts/${id}`,
      )
      return response.data
    },
  })
}

export const useAlertFormDataQuery = () => {
  return useQuery({
    queryKey: ["alertFormData"],
    queryFn: async () => {
      const response = await apiClient.get<AlertsResponses.AlertFormData>(
        "/alerts/creationPage",
      )
      return response.data
    },
  })
}

export const useCreateAlertMutation = () => {
  return useMutation({
    mutationFn: async (alert: AlertsRequests.AlertCreation) => {
      const response = await apiClient.post<
        AlertsResponses.AlertFormData,
        AxiosResponse<AlertsResponses.AlertFormData>,
        AlertsRequests.AlertCreation
      >("/alerts", alert)
      return response.data
    },
    onSuccess: async () =>
      await queryClient.invalidateQueries({ queryKey: ["alerts"] }),
  })
}

export const useUpdateAlertMutation = (alertId: string) => {
  return useMutation({
    mutationFn: async (alert: AlertsRequests.AlertEdition) => {
      const response = await apiClient.put<
        AlertsResponses.Alert,
        AxiosResponse<AlertsResponses.Alert>,
        AlertsRequests.AlertEdition
      >(`/alerts/${alertId}`, alert)
      return response.data
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["alerts"] }),
  })
}

export const useDeleteAlertMutation = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      await apiClient.delete(`/alerts/${id}`)
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["alerts"] }),
  })
}
