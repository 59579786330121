import {
  Button,
  Group,
  InputWrapper,
  MultiSelect,
  Paper,
  Select,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core"
import { MonthPickerInput } from "@mantine/dates"
import { useForm, zodResolver } from "@mantine/form"
import { openConfirmModal } from "@mantine/modals"
import { showNotification } from "@mantine/notifications"
import { IconBriefcaseOff } from "@tabler/icons-react"
import { useEffect, useMemo, useState } from "react"
import { useBlocker, useNavigate, useParams } from "react-router-dom"
import { z } from "zod"

import {
  BudgetsRequests,
  BudgetsResponses,
} from "@costory/types/endpoints/budgets"
import { CurrencyOptions } from "@costory/types/filters"
import { BudgetStatus, VirtualDimension } from "@costory/types/prisma-client"

import dayjs from "@costory/shared/dayjs"

import {
  BudgetTable,
  BudgetTableLine,
} from "@costory/front/pages/Budgets/BudgetTable"
import {
  useCreateBudgetMutation,
  useUpdateBudgetMutation,
} from "@costory/front/queries/budgets"
import { PropsWithData } from "@costory/front/utils/propsWithData"

const EmptyBudgetTable = () => (
  <Paper flex={1} mah="70vh" styles={{ root: { overflowY: "scroll" } }} p={0}>
    <Stack align="center" mt="20vh">
      <IconBriefcaseOff color="var(--mantine-color-primary-6)" size={60} />
      <Title order={3}>Select a virtual dimension to start</Title>
    </Stack>
  </Paper>
)

export interface BudgetFormValues
  extends Omit<
    BudgetsRequests.CreateBudget,
    "year" | "month" | "lines" | "status"
  > {
  startDate: Date
}
interface BudgetFormProps
  extends PropsWithData<BudgetsResponses.BudgetFormData> {
  initialValues?: {
    formValues: BudgetFormValues
    lines: BudgetTableLine[]
  }
}

const initialLine = {
  virtualDimensionValue: "",
  ownerId: "",
  allocations: new Array(12).fill(0),
  businessMetricId: "",
}

const initialLines = new Array(12).fill({ ...initialLine })

export const BudgetForm = ({ data, initialValues }: BudgetFormProps) => {
  const { budgetId } = useParams<{ budgetId?: string }>()
  const isEditMode = Boolean(initialValues)
  const { availableVirtualDimensions, availableBusinessMetrics } = data

  const navigate = useNavigate()
  const {
    mutateAsync: createBudget,
    isPending: isCreateBudgetMutationPending,
    isSuccess: isCreateBudgetMutationSuccess,
  } = useCreateBudgetMutation()
  const {
    mutateAsync: updateBudget,
    isPending: isUpdateBudgetMutationPending,
    isSuccess: isUpdateBudgetMutationSuccess,
  } = useUpdateBudgetMutation()

  const blocker = useBlocker(({ historyAction }) => historyAction !== "POP")
  useEffect(() => {
    if (blocker.state === "blocked") {
      if (isCreateBudgetMutationSuccess || isUpdateBudgetMutationSuccess) {
        blocker.proceed()
        return
      }

      openConfirmModal({
        title: <Title order={2}>Are you sure ?</Title>,
        children: (
          <Text>
            Make sure you don&apos;t have unsaved changes before you leave
          </Text>
        ),
        onClose: blocker.reset,
        onConfirm: blocker.proceed,
        labels: {
          cancel: "Stay",
          confirm: "Leave",
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker.state])

  const isFormPending =
    isCreateBudgetMutationPending || isUpdateBudgetMutationPending

  const form = useForm<BudgetFormValues>({
    mode: "uncontrolled",
    initialValues: initialValues?.formValues ?? {
      name: "",
      startDate: dayjs().add(1, "year").add(1, "day").startOf("year").toDate(),
      virtualDimensionId: "",
      tagsIds: [],
      currency: "USD",
    },
    validate: zodResolver(
      z.object({
        name: z.string().min(3, "Name must be at least 3 characters"),
        startDate: z.date(),
        virtualDimensionId: z.string(),
        tagsIds: z.array(z.string()),
      }),
    ),
  })

  const [lines, setLines] = useState<BudgetTableLine[]>(
    initialValues?.lines ?? initialLines,
  )

  useEffect(() => {
    if (initialValues) {
      setLines(initialValues.lines)
    }
  }, [initialValues])

  const getVirtualDimension = (id: string) =>
    availableVirtualDimensions.find((vd) => vd.id === id)

  const [selectedVirtualDimension, setSelectedVirtualDimension] = useState<
    VirtualDimension | undefined
  >(getVirtualDimension(form.getValues().virtualDimensionId) ?? undefined)

  form.watch("virtualDimensionId", () => {
    return setSelectedVirtualDimension(
      getVirtualDimension(form.getValues().virtualDimensionId),
    )
  })

  const resetLines = () => {
    setLines(
      initialValues?.lines ??
        (selectedVirtualDimension
          ? selectedVirtualDimension.values.map((value) => ({
              ...initialLine,
              virtualDimensionValue: value,
            }))
          : []),
    )
  }

  useEffect(() => {
    resetLines()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVirtualDimension])

  const virtualDimensionsSelectItems = useMemo(
    () =>
      availableVirtualDimensions.map((vd) => ({
        value: vd.id,
        label: vd.name,
      })),
    [availableVirtualDimensions],
  )

  const businessMetricsSelectItems = useMemo(() => {
    return availableBusinessMetrics.map((metric) => ({
      value: metric.id,
      label: metric.name,
    }))
  }, [availableBusinessMetrics])

  const ownersSelectItems = useMemo(
    () =>
      data.organizationUsers.map((user) => ({
        value: user.id,
        label: user.fullName,
      })),
    [data.organizationUsers],
  )

  const handleSubmit = async (status: BudgetStatus) => {
    const res = form.validate()
    if (res.hasErrors) {
      return
    }
    const { startDate, ...formValues } = form.getValues()
    const submittedValues = {
      ...formValues,
      status,
      year: dayjs(startDate).year(),
      month: dayjs(startDate).month(),
      lines,
    }
    try {
      const createdBudget = await (isEditMode
        ? updateBudget({ id: budgetId!, ...submittedValues })
        : createBudget(submittedValues))
      showNotification({
        title: "Budget created",
        message: "Your budget has been created",
        color: "green",
      })
      navigate(`/budgets/${createdBudget.id}`)
    } catch (error) {
      showNotification({
        title: "An error occured",
        message: error as string,
        color: "red",
      })
    }
  }

  const setLine = (
    index: number,
    value: BudgetTableLine | ((prev: BudgetTableLine) => BudgetTableLine),
  ) => {
    setLines((prev) => [
      ...prev.slice(0, index),
      typeof value === "function" ? value(prev[index]) : value,
      ...prev.slice(index + 1),
    ])
  }

  return (
    <Stack h="100%" gap={32}>
      <Stack>
        <Group justify="space-between" align="start">
          <Group>
            <InputWrapper label="Budget name" required>
              <TextInput
                {...form.getInputProps("name")}
                placeholder="Budget 2026"
                maw="30vw"
              />
            </InputWrapper>
            <InputWrapper label="Virtual dimension" required>
              <Select
                disabled={isEditMode}
                data={virtualDimensionsSelectItems}
                placeholder="Select virtual dimension"
                {...form.getInputProps("virtualDimensionId")}
              />
            </InputWrapper>
            <InputWrapper label="First month" required>
              <MonthPickerInput
                disabled={isEditMode}
                value={form.getValues().startDate}
                onChange={(value) => {
                  if (!value) {
                    return
                  }
                  form.setFieldValue(
                    "startDate",
                    // Add one day because depending on local time, the month picker can return the last day of the month in UTC
                    dayjs(value).add(1, "day").toDate(),
                  )
                }}
                placeholder="Select first month"
              />
            </InputWrapper>
            <InputWrapper label="Last month" required>
              <MonthPickerInput
                disabled
                value={dayjs(form.getValues().startDate)
                  .add(11, "month")
                  .toDate()}
              />
            </InputWrapper>{" "}
            <InputWrapper label="Currency" required>
              <Select
                disabled={isEditMode}
                data={CurrencyOptions}
                placeholder="Select Currency"
                {...form.getInputProps("currency")}
              />
            </InputWrapper>
          </Group>
          <Group>
            <InputWrapper label="Add tags (optional)">
              <MultiSelect
                data={data.availableTags.map((tag) => ({
                  value: tag.id,
                  label: tag.name,
                }))}
                {...form.getInputProps("tagsIds")}
                placeholder="Select tags"
              />
            </InputWrapper>
          </Group>
        </Group>
      </Stack>
      {selectedVirtualDimension ? (
        <BudgetTable
          virtualDimension={selectedVirtualDimension}
          availableOwners={ownersSelectItems}
          availableBusinessMetrics={businessMetricsSelectItems}
          setLine={setLine}
          lines={lines}
          startDate={form.getValues().startDate}
          currency={form.getValues().currency}
        />
      ) : (
        <EmptyBudgetTable />
      )}
      <Group justify="end">
        <Button
          color="primary"
          variant="outline"
          onClick={() => handleSubmit(BudgetStatus.DRAFT)}
        >
          Save as draft
        </Button>
        <Button
          color="primary"
          onClick={() => handleSubmit(BudgetStatus.VALIDATED)}
          loading={isFormPending}
        >
          {isEditMode ? "Save changes" : "Create Budget"}
        </Button>
      </Group>
    </Stack>
  )
}
