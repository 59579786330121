import { Box, Button, Stack, Title } from "@mantine/core"
import { useNavigate } from "react-router-dom"

export const PageNotFound = () => {
  const navigate = useNavigate()
  const handleGoBack = () => {
    // If the user lands directly on an invalid URL, redirect the user to the home page
    // since there is no valid URL to navigate back to.
    if (window.history.length > 2) {
      navigate(-1)
    } else {
      navigate("/home")
    }
  }

  return (
    <Stack h="70vh" justify="center" align="center">
      <Title>404 Page Not Found</Title>
      <Box>Sorry, we couldn&apos;t find the page you are looking for.</Box>
      <Button onClick={handleGoBack}>Go Back</Button>
    </Stack>
  )
}
