import { ComboboxItem, ComboboxParsedItem, isOptionsGroup } from "@mantine/core"
import { useCallback, useMemo } from "react"

export const useFilteredOptions = (
  data: ComboboxParsedItem[],
  search: string,
) => {
  const filterOption = useCallback((option: ComboboxItem, search: string) => {
    return option.value.toLowerCase().includes(search.toLowerCase().trim())
  }, [])
  const filteredOptions = useMemo(() => {
    return data
      .map((option) => {
        if (isOptionsGroup(option)) {
          return {
            ...option,
            items: option.items.filter((item) => filterOption(item, search)),
          }
        }
        return filterOption(option, search) ? option : null
      })
      .filter((o): o is ComboboxParsedItem => o != null)
  }, [data, filterOption, search])

  return filteredOptions
}
