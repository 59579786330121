import { BarChart } from "@mantine/charts"
import { Modal, Stack, Title } from "@mantine/core"

import { DataHealthResponses } from "@costory/types/endpoints/dataHealth"

export function ModalDetails({
  opened,
  onClose,
  data,
}: {
  opened: boolean
  onClose: () => void
  data: DataHealthResponses.DataHealthStatus | undefined
}) {
  if (data) {
    return (
      <Modal opened={opened} onClose={onClose}>
        <Stack>
          <Title style={{ overflowWrap: "break-word" }}>
            {data.datasource} Details
          </Title>
          <BarChart
            h={300}
            data={data.costLastMonths}
            dataKey="month"
            series={[{ name: "cost", label: "Cost", color: "primary" }]}
          />
        </Stack>
      </Modal>
    )
  }
}
