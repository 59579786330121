import { showNotification } from "@mantine/notifications"
import { useNavigate, useParams } from "react-router-dom"

import { BudgetsResponses } from "@costory/types/endpoints/budgets"

import dayjs from "@costory/shared/dayjs"

import { useUser } from "@costory/front/components/auth/UserContext"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import {
  BudgetForm,
  BudgetFormValues,
} from "@costory/front/pages/Budgets/BudgetForm"
import {
  useBudgetFormDataQuery,
  useGetBudgetQuery,
} from "@costory/front/queries/budgets"

interface BudgetEditionPageProps {
  budgetData: BudgetsResponses.GetBudget
  formData: BudgetsResponses.BudgetFormData
}

const _BudgetEditionPage = ({
  budgetData: { budget },
  formData,
}: BudgetEditionPageProps) => {
  const user = useUser()
  const navigate = useNavigate()

  const startDate = dayjs()
    .year(budget.year)
    .month(budget.startMonth)
    .add(1, "day")
    .startOf("month")
    .toDate()

  const intialFormValues: BudgetFormValues = {
    name: budget.name,
    tagsIds: budget.tags.map((tag) => tag.id),
    startDate,
    virtualDimensionId: budget.virtualDimension.id,
    currency: "USD",
  }

  if (budget.createdBy.id !== user.id) {
    showNotification({
      title: "Unauthorized",
      message: "You are not allowed to edit this budget",
      color: "red",
    })
    navigate("/budgets")
    return null
  }

  return (
    <BudgetForm
      initialValues={{ formValues: intialFormValues, lines: budget.lines }}
      data={formData}
    />
  )
}

export const BudgetEditionPage = () => {
  const { budgetId } = useParams<{ budgetId: string }>()
  const formDataQuery = useBudgetFormDataQuery()
  const budgetQuery = useGetBudgetQuery(budgetId!)

  return (
    <QueryWrapper query={budgetQuery}>
      {({ data: budgetData }) => (
        <QueryWrapper query={formDataQuery}>
          {({ data: formData }) => (
            <_BudgetEditionPage budgetData={budgetData} formData={formData} />
          )}
        </QueryWrapper>
      )}
    </QueryWrapper>
  )
}
