import { UseFormReturnType } from "@mantine/form"
import dayjs from "dayjs"
import _ from "lodash"
import qs from "qs"
import { formatQuery, add } from "react-querybuilder"
import { v4 as uuidv4 } from "uuid"

import { DigestResponses } from "@costory/types/endpoints/digest"

import { computePresetDates } from "@costory/shared/utils/filters"

import { getChild } from "@costory/front/pages/Digest/digest"
import { formatNumber } from "@costory/front/utils/format"

export type Order = "cost" | "absChange" | "relativeChange"

export type NodeInvestigation = {
  isOther: boolean
  othersUuid?: string
  uuid: string
  parent?: string
  position: { key: string; value: string }[]
  direction: string
  displayOptions: {
    text: string
    displayContext: boolean
    orderByColumn: Order
    order: "asc" | "desc"
  }
  data: {
    diff: number
    nbrDays?: string
    index?: string
    cost_test?: number
    minDate?: string
    maxDate?: string
    cost_previous?: number
  }
  labels?: string[]
}
export type TreeData = {
  label: string
  value: string
  nodeProps: NodeInvestigation
  children: TreeData[]
}

export type Form = {
  nodes: NodeInvestigation[]
  labels: { key: string; value: string }[]
}
export function getOptions(
  data: DigestResponses.Investigation,
  parent: { key: string; value: string }[],
) {
  const optionsAvailable = _(getChild(parent, data, 1))
    .groupBy(
      (d) =>
        d.arr.filter((k) => parent.map((l) => l.key).indexOf(k.key) == -1)[0]
          .key,
    )
    .map((values, key) => {
      return {
        direction: key,
        totalDiff: Math.round(
          _.sum(
            values
              .filter(
                (el) => _.sum(el.arr.map((e) => e.value === "__NULL_")) == 0,
              )
              .map((el) => el.diff),
          ),
        ),
        nbrValue: values.length,
      }
    })
    .value()
  const filtered = _(optionsAvailable)
    .orderBy((d) => d.totalDiff, "desc")
    .value()
  return filtered
}
export function deleteChild(value: string, form: UseFormReturnType<Form>) {
  const nodes = form.getValues().nodes
  const idx = nodes.findIndex((el) => el.uuid === value)
  form.removeListItem("nodes", idx)
  // if parent has no child and is a top --> delete as well:
  const parentId = nodes[idx].parent
  const nodesAfterDeletion = form.getValues().nodes
  const allChilds = nodesAfterDeletion.filter(
    (el) => el.parent == parentId,
  ).length
  if (
    allChilds === 0 &&
    parentId &&
    !nodesAfterDeletion.find((el) => el.uuid === parentId)?.parent
  ) {
    deleteChild(parentId, form)
  }
}
export function deleteAllChilds(value: string, form: UseFormReturnType<Form>) {
  const nodes = form.getValues().nodes
  const toKeep = nodes.filter((el) => el.parent != value)
  form.setValues({ nodes: toKeep })
}

export function saveTagsNodes(
  labels: string[],
  uuids: string[],
  form: UseFormReturnType<Form>,
) {
  const nodes = form.getValues().nodes
  form.setValues({
    nodes: nodes.map((el) =>
      uuids.includes(el.uuid)
        ? {
            ...el,
            labels: el.labels
              ? _.uniqBy(labels.concat(el.labels), (a) => a.split(":")[0])
              : labels,
          }
        : { ...el },
    ),
  })
}

export function addContext(
  form: UseFormReturnType<Form>,
  uuid: string,
  ctx: {
    text?: string
    displayContext?: boolean
    orderByColumn?: Order
    order?: "asc" | "desc"
  },
) {
  const nodes = form.getValues().nodes
  const node = nodes.filter((val) => val.uuid === uuid)[0]
  const newNode = {
    ...node,
    displayOptions: { ...node.displayOptions, ...ctx },
  } as NodeInvestigation
  const nodeIndex = nodes.findIndex((val) => val.uuid === uuid)
  form.removeListItem("nodes", nodeIndex)
  form.insertListItem("nodes", newNode)
}

export function addChild(
  form: UseFormReturnType<Form>,
  direction: string,
  data: DigestResponses.Investigation,
  parentUuid: string | null,
) {
  const nodes = form.getValues().nodes
  const childs = getChild(
    parentUuid == null
      ? []
      : (nodes.filter((el) => el.uuid === parentUuid)[0].position ?? []),
    data,
    1,
  ).filter((elem) => elem.arr.map((e) => e.key).indexOf(direction) > -1)
  const uuidOthers = Math.random().toString()
  childs.forEach((el) => {
    form.insertListItem("nodes", {
      isOther: false,
      othersUuid: uuidOthers,
      uuid: Math.random().toString(),
      displayOptions: {
        text: "",
        displayContext: false,
        orderByColumn: "absChange",
        order: "desc",
      },
      direction,
      position: el.arr,
      data: el,
      label: "",
      parent: parentUuid,
    } as NodeInvestigation)
  })
}

export function convertToTree(nodes: NodeInvestigation[]) {
  const nodesMap = new Map<string, TreeData>()
  nodes.forEach((node) =>
    nodesMap.set(node.uuid, {
      children: [],
      label: node.uuid,
      value: node.uuid,
      nodeProps: node,
    }),
  )
  nodes.forEach((node) => {
    const parent = node.parent
    if (parent) {
      nodesMap.get(node.parent!)?.children.push(nodesMap.get(node.uuid)!)
    }
  })
  nodes.forEach((node) => {
    const nodeData = nodesMap.get(node.uuid)
    if (nodeData?.children.length && nodeData.nodeProps.parent) {
      const grouped = _(nodeData?.children)
        .groupBy((d) => d.nodeProps.direction)
        .map((vals, direction) => {
          const totalShouldBeExplained = nodeData.nodeProps.data.diff
          return {
            direction,
            diff:
              totalShouldBeExplained -
              _.sumBy(vals, (d) => d.nodeProps.data.diff),
            previousCost:
              (nodeData.nodeProps.data.cost_previous || 0.0) -
              _.sumBy(vals, (d) => d.nodeProps.data.cost_previous || 0.0),
            testCost:
              (nodeData.nodeProps.data.cost_test || 0.0) -
              _.sumBy(vals, (d) => d.nodeProps.data.cost_test || 0.0),
          }
        })
        .value()
      const getOtherTree = (
        diff: number,
        direction: string,
        // eslint-disable-next-line camelcase
        cost_previous: number,
        // eslint-disable-next-line camelcase
        cost_test: number,
      ) =>
        ({
          label: Math.random().toString(),
          value: Math.random().toString(),
          nodeProps: {
            isOther: true,
            uuid: "",
            // eslint-disable-next-line camelcase
            data: { diff, direction, cost_previous, cost_test },
            position: [],
            direction,
            displayOptions: {
              text: "",
              displayContext: false,
              orderByColumn: "absChange",
              order: "desc",
            },
          },
          children: [],
        }) as TreeData
      grouped
        .map((el) => {
          return getOtherTree(
            el.diff,
            el.direction,
            el.previousCost,
            el.testCost,
          )
        })
        .forEach((el) => nodeData?.children.push(el))
    }
  })
  nodes.forEach((node) => {
    const parent = node.parent
    if (parent) {
      const parentNode = nodesMap.get(parent!)
      if (parentNode) {
        const col = parentNode.nodeProps.displayOptions.orderByColumn
        parentNode.children = _.orderBy(
          parentNode.children,
          (d) =>
            col === "absChange"
              ? Math.abs(d.nodeProps.data.diff)
              : col === "cost"
                ? Math.abs(d.nodeProps.data.cost_test || 0.00001)
                : Math.abs(
                    d.nodeProps.data.diff /
                      ((d.nodeProps.data.cost_previous || 0.0000001) +
                        0.0000001),
                  ),
          parentNode.nodeProps.displayOptions.order,
        )
      }
    }
  })
  const parentNodes = nodes.filter((el) => !el.parent).map((el) => el.uuid)
  return parentNodes.map((el) => nodesMap.get(el)).filter((el) => !!el)
}
export function formatSlice(
  node: NodeInvestigation,
  direction?: string,
  mapper?: Map<string, string>,
  showText?: boolean,
  hideDiff?: boolean,
) {
  const label =
    node.data.cost_test &&
    node.data.cost_previous &&
    `: ${formatNumber(node.data.cost_previous)} -> ${formatNumber(node.data.cost_test)}`
  const directionLabel = mapper
    ? mapper.get(direction!) || direction
    : direction
  const relativeDiff = formatNumber(
    node.data.diff / node.data.cost_previous!,
    "percent",
    1,
  )
  const diffFormatted = formatNumber(node.data.diff)
  if (node.isOther) {
    return `${directionLabel} others: ${diffFormatted} ${label || ""} ${showText ? node.displayOptions.text : ""}`
  }
  if (node.position.length == 0) {
    return `${directionLabel} ${label || ""} ${showText ? node.displayOptions.text : ""}`
  }
  if (direction) {
    const axis = node.position.filter((el) => el.key === direction)[0].value
    return `${directionLabel}: ${axis}:  ${diffFormatted} ${!hideDiff ? `(\` ${relativeDiff}\`)` : ""} ${label || ""} ${showText ? node.displayOptions.text : ""}`
  }
}

export function treeToBulletPoints(
  node: TreeData,
  mapper: Map<string, string>,
  dateThreshold: string,
  depth = 0,
) {
  let bulletPoints = ""

  // Indentation based on depth
  const indentation = "\t".repeat(depth)
  const dateCheck = detectOldNew(node.nodeProps, dateThreshold)
  // Add the current node as a bullet point
  const label = formatSlice(
    node.nodeProps,
    node.nodeProps.direction,
    mapper,
    true,
  )
  const { queryFormatted } = getquery(node)
  const link = "[Open](https://app.costory.io/explorer?" + queryFormatted + ")"
  const text = queryFormatted ? link : ""
  bulletPoints +=
    `${indentation}- ${label} ` +
    text +
    `${dateCheck.conditionDropped ? ` (Dropped Resource last seen ${node.nodeProps.data.maxDate} first seen ${node.nodeProps.data.minDate}) ` : ""}` +
    ` ${dateCheck.conditionNew ? ` ( New Resource first seen ${node.nodeProps.data.minDate} last seen ${node.nodeProps.data.maxDate}) ` : ""} \n`
  node.children.forEach((child) => {
    bulletPoints += treeToBulletPoints(child, mapper, dateThreshold, depth + 1)
  })

  return bulletPoints
}

export function getquery(node: TreeData) {
  const bestGroupBy =
    node.children.map((el) => el.nodeProps.direction)[0] ?? "cos_provider"
  let query = { combinator: "and", rules: [] }
  node.nodeProps.position.forEach((el) => {
    query = add(
      query,
      {
        id: uuidv4(),
        field: el.key,
        operator: "in",
        value: [el.value],
      },
      [],
    )
  })
  const queryFormatted =
    node.nodeProps.position.length !== 0
      ? qs.stringify(
          {
            metricId: "cost",
            currency: "USD",
            limit: 10,
            aggBy: "Day",
            groupBy: bestGroupBy,
            ...computePresetDates("LAST_MONTH"),
            whereClause: encodeURIComponent(
              formatQuery(query, "json_without_ids"),
            ),
          },
          {
            encode: false,
          },
        )
      : null
  return { queryFormatted }
}

export function formatMarkdown(
  form: UseFormReturnType<Form>,
  mapper: Map<string, string>,
  dateThreshold: string,
) {
  const data = convertToTree(form.getValues().nodes)
  return data.map((el) => treeToBulletPoints(el, mapper, dateThreshold))
}

export function detectOldNew(node: NodeInvestigation, thresholdMin: string) {
  const conditionNew =
    node.data.minDate && dayjs(node.data.minDate) > dayjs(thresholdMin)
  const conditionDropped =
    node.data.maxDate && dayjs(node.data.maxDate) < dayjs(thresholdMin)
  return {
    conditionNew,
    conditionDropped,
    minDate: node.data.minDate,
    maxDate: node.data.maxDate,
  }
}
