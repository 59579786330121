import { Button, Stack, Text, ThemeIcon, Title } from "@mantine/core"
import { IconLayoutOff, IconPlus } from "@tabler/icons-react"
import { FC } from "react"

interface EmptyDashboardProps {
  onClickAddWidget: () => void
}

export const EmptyDashboard: FC<EmptyDashboardProps> = ({
  onClickAddWidget,
}) => {
  return (
    <Stack align="center" py={40}>
      <ThemeIcon size={70}>
        <IconLayoutOff size={70} />
      </ThemeIcon>
      <Title>This dashbboard is empty</Title>
      <Text fz="xl">Start by adding a widget</Text>
      <Stack align="stretch" gap={8}>
        <Button onClick={onClickAddWidget} leftSection={<IconPlus />}>
          Add a widget
        </Button>
      </Stack>
    </Stack>
  )
}
