import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import {
  SavedViewsRequests,
  SavedViewsResponses,
} from "@costory/types/endpoints/savedViews"
import { ComponentType } from "@costory/types/prisma-client"

import { Widget } from "@costory/front/components/dashboard/DashboardLayout"
import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export const useListSavedViewsQuery = () => {
  return useQuery({
    queryKey: ["saved-views"],
    queryFn: async () => {
      const response =
        await apiClient.get<SavedViewsResponses.SavedView[]>("/saved-views")
      return response.data
    },
  })
}

export const useSavedViewQuery = (viewId?: string) => {
  return useQuery<SavedViewsResponses.SavedView>({
    queryKey: ["saved-views", viewId],
    queryFn: async () => {
      const response = await apiClient.get(`/saved-views/${viewId}`)
      return response.data
    },
    enabled: !!viewId,
  })
}

export type SavedViewRedirectPage = "explorer" | "waterfall"

export const useCreateSavedViewMutation = (
  redirectPage: SavedViewRedirectPage,
) => {
  const navigate = useNavigate()
  return useMutation({
    mutationFn: async (view: SavedViewsRequests.EditSavedView) => {
      const response = await apiClient.post<SavedViewsResponses.SavedView>(
        "/saved-views",
        view,
      )
      return response.data
    },
    onSuccess: async (savedView) => {
      await queryClient.invalidateQueries({ queryKey: ["saved-views"] })
      await queryClient.invalidateQueries({
        queryKey: ["folders", savedView.parentFolderId],
      })
      await queryClient.invalidateQueries({ queryKey: ["folders", "tree"] })
      showNotification({
        color: "green",
        title: "View saved !",
        message: "View saved successfully",
      })
      navigate(`/${redirectPage}/views/${savedView.id}`)
    },
    onError: (error) => {
      showNotification({
        color: "red",
        title: "Error",
        message: error.message,
      })
    },
  })
}

export const useUpdateSavedViewMutation = (viewId?: string) => {
  return useMutation({
    mutationFn: async (view: SavedViewsRequests.EditSavedView) => {
      if (!viewId) return
      const response = await apiClient.put<SavedViewsResponses.SavedView>(
        `/saved-views/${viewId}`,
        view,
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["saved-views"] })
      await queryClient.invalidateQueries({ queryKey: ["folders", "tree"] })
      showNotification({
        color: "green",
        title: "View updated !",
        message: "View updated successfully",
      })
    },
    onError: (error) => {
      showNotification({
        color: "red",
        title: "Error",
        message: error.message,
      })
    },
  })
}

export const useUpdateWidgetMutation = () => {
  const updateSavedViewMutation = useMutation({
    mutationFn: async (
      view: SavedViewsRequests.EditSavedView & { viewId: string },
    ) => {
      if (!view.viewId) return
      await apiClient.put<SavedViewsResponses.SavedView>(
        `/saved-views/${view.viewId}`,
        view,
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["saved-views"] })
      await queryClient.invalidateQueries({ queryKey: ["folders", "tree"] })
      showNotification({
        color: "green",
        title: "Success",
        message: "Successfully archived",
      })
    },
    onError: (error: { error: string }) => {
      showNotification({
        color: "red",
        title: "Error",
        message: error.error,
      })
    },
  })

  const updateWidget = async (
    widgets: Widget[],
    savedViews: SavedViewsResponses.SavedView[],
    dashboardType: ComponentType,
  ) => {
    const savedViewIds = widgets.map(({ viewId }) => viewId)
    savedViewIds.map(async (savedViewId) => {
      const view = savedViews.find(({ id }) => id === savedViewId)
      if (
        dashboardType === ComponentType.COMMUNITY &&
        view?.type !== ComponentType.COMMUNITY
      ) {
        await updateSavedViewMutation.mutateAsync({
          ...view,
          viewId: savedViewId,
          type: ComponentType.COMMUNITY,
          parentFolderId: view?.parentFolderId,
        })
      }
    })
  }

  return { updateWidget }
}

export const useDeleteSavedView = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await apiClient.delete(`/saved-views/${id}`)
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["saved-views"] })
      await queryClient.invalidateQueries({ queryKey: ["folders", "tree"] })
      showNotification({
        color: "green",
        title: "Success",
        message: "Successfully archived",
      })
    },
    onError: (error: { error: string }) => {
      showNotification({
        color: "red",
        title: "Error",
        message: error.error,
      })
    },
  })
}

export const useRenameSavedView = () => {
  return useMutation({
    mutationFn: async ({ id, name }: { id: string; name: string }) => {
      const response = await apiClient.put<SavedViewsResponses.RenameSavedView>(
        `/saved-views/${id}`,
        { name },
      )
      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["saved-views"] })
      await queryClient.invalidateQueries({ queryKey: ["folders"] })
    },
  })
}
