import { ClientSideSuspense } from "@liveblocks/react"
import { RoomProvider, useThreads } from "@liveblocks/react/suspense"
import { Composer, Thread } from "@liveblocks/react-ui"
import {
  Button,
  CloseButton,
  Flex,
  Loader,
  Paper,
  Popover,
  Text,
} from "@mantine/core"
import { ErrorBoundary } from "@sentry/react"
import { IconMessageCircleQuestion } from "@tabler/icons-react"
import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"

const paperStyles = {
  overflowY: "auto" as const,
  borderLeft: 0,
  borderRight: 0,
}
const threadPaperStyles = (index: number, threadsLength: number) => ({
  overflowY: "auto" as const,
  borderTop: index === 0 ? 0 : undefined,
  borderBottom: index === threadsLength - 1 ? 0 : undefined,
})

function CommentComponents({ openThreadId }: { openThreadId: string | null }) {
  const { threads } = useThreads()
  const threadRefs = useRef<Array<HTMLDivElement | null>>([])

  useEffect(() => {
    if (openThreadId) {
      const targetIndex = threads?.findIndex(
        (thread) => thread.id === openThreadId,
      )
      const targetElement = threadRefs.current[targetIndex]
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" })
      }
    }
  }, [openThreadId, threads])

  return (
    <>
      <Paper p={0} mah="24rem" style={paperStyles} pb={0}>
        {threads?.length ? (
          threads.map((thread, index) => (
            <Paper
              key={thread.id}
              mt={index === 0 ? 0 : 16}
              mah="24rem"
              style={threadPaperStyles(index, threads.length)}
              ref={(element) => (threadRefs.current[index] = element)}
            >
              <Thread thread={thread} />
            </Paper>
          ))
        ) : (
          <Paper style={{ borderTop: 0, borderBottom: 0 }}>
            <Text size="sm">No threads available.</Text>
          </Paper>
        )}
      </Paper>
      <Paper p={2} mt={10} withBorder>
        <Composer />
      </Paper>
    </>
  )
}
export default function LiveBlocks({ roomId }: { roomId: string | undefined }) {
  const location = useLocation()
  const [openTreads, setOpenTreads] = useState<boolean>(false)
  const [openThreadId, setOpenThreadId] = useState<string | null>(null)

  useEffect(() => {
    const threadId = new URLSearchParams(location.search).get("threadId")
    if (threadId) {
      setOpenTreads(true)
      setOpenThreadId(threadId)
    }
  }, [location.search])

  const toggleTreads = () => setOpenTreads((prev) => !prev)

  return (
    roomId && (
      <>
        <Paper
          withBorder
          p={0}
          pos="fixed"
          bottom={10}
          right={70}
          style={{ cursor: "pointer", zIndex: 50 }}
          radius={30}
        >
          <Popover opened={openTreads} width={400}>
            <Popover.Target>
              <Button
                onClick={toggleTreads}
                radius={9999}
                p={10}
                h="fit-content"
              >
                <IconMessageCircleQuestion size="30" />
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <Flex justify="flex-end">
                <CloseButton
                  bg="transparent"
                  size="sm"
                  onClick={() => setOpenTreads(false)}
                />
              </Flex>
              <RoomProvider id={roomId}>
                <ErrorBoundary
                  fallback={
                    <Text>There was an error while getting threads.</Text>
                  }
                >
                  <ClientSideSuspense fallback={<Loader />}>
                    <CommentComponents openThreadId={openThreadId} />
                  </ClientSideSuspense>
                </ErrorBoundary>
              </RoomProvider>
            </Popover.Dropdown>
          </Popover>
        </Paper>
      </>
    )
  )
}
