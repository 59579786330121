import { memo } from "react"

export const Th = memo(function _Th(
  props: React.HTMLProps<HTMLTableCellElement>,
) {
  return (
    <th
      {...props}
      style={{
        borderRight: "1px solid var(--mantine-color-gray-3)",
        borderBottom: "1px solid var(--mantine-color-gray-3)",
        backgroundColor: "#f0f0f0",
        textAlign: "left",
        padding: 4,
        height: 40,
        width: 100,
        wordWrap: "break-word",
        maxWidth: 150,
        ...props.style,
      }}
    >
      {props.children}
    </th>
  )
})
