import {
  ActionIcon,
  Button,
  Group,
  Modal,
  Select,
  Stack,
  Table,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconCalculator } from "@tabler/icons-react"
import _ from "lodash"
import { useState } from "react"

import { AggBy, Currency } from "@costory/types/prisma-client"

import { computePresetDates } from "@costory/shared/utils/filters"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { NodeInvestigation } from "@costory/front/pages/Digest/helper"
import { useBusinessMetricsQuery } from "@costory/front/queries/businessMetrics"
import { useExplorerQuery } from "@costory/front/queries/explore"
import { formatNumber } from "@costory/front/utils/format"

export function Contextualize({
  node,
  store,
}: {
  node: NodeInvestigation
  store: (val: string) => void
}) {
  if (node.isOther || node.position.length === 0) {
    return <></>
  }
  return (
    <>
      <Group>
        <ChooseContext node={node} store={store} />
      </Group>
    </>
  )
}

function ChooseContext({
  node,
  store,
}: {
  node: NodeInvestigation
  store: (val: string) => void
}) {
  const [businessMetricId, setBusinessMetricId] = useState<{
    label: string
    id: string
  }>({ id: "cost", label: "cost" })
  const [currency, setCurrency] = useState<Currency>("USD")
  const explorerFilter = {
    metricId: businessMetricId?.id || "cost",
    currency,
    limit: 10,
    groupBy: "cos_provider",
    whereClause: {
      combinator: "AND",
      rules: node.position.map((elem) => ({
        field: elem.key,
        operator: "in",
        value: [elem.value],
      })),
    },
    ...computePresetDates("LAST_3_MONTHS"),
    aggBy: AggBy.Month,
  }
  const queryExplorer = useExplorerQuery(explorerFilter)
  const query = useBusinessMetricsQuery()
  const [opened, handlers] = useDisclosure()
  return (
    <>
      <QueryWrapper query={query}>
        {(businessMetrics) => (
          <>
            <ActionIcon variant="transparent" onClick={() => handlers.open()}>
              <IconCalculator
                style={{ width: "70%", height: "70%" }}
                stroke={1.5}
              />
            </ActionIcon>
            <Modal opened={opened} onClose={handlers.close} size="xl">
              <Stack>
                <Select
                  label="Currency"
                  onChange={(val) => setCurrency(val as Currency)}
                  value={currency as string}
                  data={["USD", "EUR"]}
                />
                <Select
                  label="Business Metric"
                  onChange={(_val, opt) =>
                    setBusinessMetricId({
                      id: opt.value,
                      label: opt.label,
                    })
                  }
                  value={businessMetricId.id as string}
                  data={businessMetrics.data
                    .filter((el) => !("technical" in el))
                    .map((el) => ({
                      label: el.name,
                      value: el.id,
                    }))}
                />
                <QueryWrapper query={queryExplorer}>
                  {function (dataExplorer) {
                    const table = dataExplorer.data.stackedBarChartData.arr.map(
                      (el) => ({
                        date: el.agg_date,
                        sum: _.sum(
                          dataExplorer.data.stackedBarChartData.items.map(
                            (val) => el[val.name],
                          ),
                        ),
                      }),
                    )

                    const businessMetrics = dataExplorer.data.data.map(
                      (el) => ({
                        date: el.agg_date,
                        businessMetric: el.business_metric,
                        cost:
                          table.find((aa) => aa.date === el.agg_date)?.sum ||
                          0.0,
                      }),
                    )
                    const rows = businessMetrics.map((element) => (
                      <Table.Tr key={element.date}>
                        <Table.Td>{element.date}</Table.Td>
                        <Table.Td>
                          {formatNumber(element.cost, "currency", 2, currency)}
                        </Table.Td>
                        <Table.Td>
                          {formatNumber(element.businessMetric, "decimal")}
                        </Table.Td>
                      </Table.Tr>
                    ))
                    return (
                      <>
                        <Table>
                          <Table.Thead>
                            <Table.Tr>
                              <Table.Th>Month</Table.Th>
                              <Table.Th>Cost Per Business Metric</Table.Th>
                              <Table.Th>Absolute Business Metric</Table.Th>
                            </Table.Tr>
                          </Table.Thead>
                          <Table.Tbody>{rows}</Table.Tbody>
                        </Table>

                        <Button
                          variant="transparent"
                          onClick={() => {
                            store(
                              `Related to: ${businessMetricId!.label}: ${businessMetrics
                                .slice()
                                .reverse()
                                .map(
                                  (el) =>
                                    `${el.date}: ${formatNumber(el.cost, "currency", 2, currency)} for ${formatNumber(el.businessMetric, "decimal")} `,
                                )
                                .join(" - ")}`,
                            )
                            handlers.close()
                          }}
                        >
                          Store
                        </Button>
                      </>
                    )
                  }}
                </QueryWrapper>
              </Stack>
            </Modal>
          </>
        )}
      </QueryWrapper>
    </>
  )
}
