import { showNotification } from "@mantine/notifications"
import { FC } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { AlertsResponses } from "@costory/types/endpoints/alerts"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import {
  useGetAlertQuery,
  useUpdateAlertMutation,
} from "@costory/front/queries/alerts"
import { PropsWithData } from "@costory/front/utils/propsWithData"

import { AlertForm, AlertFormData } from "./AlertForm"

const AlertEditionForm: FC<PropsWithData<AlertsResponses.Alert>> = ({
  data,
}) => {
  const { mutateAsync: updateAlert } = useUpdateAlertMutation(data.id)
  const navigate = useNavigate()

  const onSave = async (values: AlertFormData) => {
    try {
      await updateAlert(values)
      showNotification({ message: "Alert updated", color: "green" })
      navigate("..")
    } catch {
      showNotification({ message: "An error occurred", color: "red" })
    }
  }

  return <AlertForm initialValues={data} onSave={onSave} mode="edit" />
}

export const AlertEditionPage = () => {
  const { alertId } = useParams<{ alertId: string }>()
  const alertQuery = useGetAlertQuery(alertId!)

  return (
    <QueryWrapper query={alertQuery}>
      {({ data }) => <AlertEditionForm data={data} />}
    </QueryWrapper>
  )
}
