import { Select, SelectProps } from "@mantine/core"
import _ from "lodash"
import { useMemo } from "react"

import { AxesResponses } from "@costory/types/endpoints/axes"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useAxesQuery } from "@costory/front/queries/axes"
import { orderDatasources, DatasourceType } from "@costory/front/utils/filters"

type _Props = {
  options?: AxesResponses.Axis[]
  allProps: SelectProps
}

const _AxesSelector = ({ options, allProps }: _Props) => {
  const optionsParsed = useMemo(() => {
    return _.sortBy(
      _.map(
        _.groupBy(options, (o) => o.groupTitle),
        (groupOptions, group) => ({
          group,
          items: groupOptions.map((opt) => ({
            value: opt.name,
            label: opt.label,
          })),
        }),
      ),
      (option) => orderDatasources(option.group as DatasourceType),
    )
  }, [options])

  return (
    <Select
      searchable
      allowDeselect={false}
      {...allProps}
      data={optionsParsed}
      // 414 px so the dropdown aligns properly with operator selector
      comboboxProps={{ width: 394, position: "bottom-start" }}
    />
  )
}
export const AxesSelector = (allProps: SelectProps) => {
  const axesQuery = useAxesQuery()

  return (
    <QueryWrapper query={axesQuery}>
      {({ data: axes }) => <_AxesSelector options={axes} allProps={allProps} />}
    </QueryWrapper>
  )
}
