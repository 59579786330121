// Generate digest I need:
// 1. A graph structure to explore cost.
// 2. A clear hierarchy.

import { DigestResponses } from "@costory/types/endpoints/digest"

export function getChild(
  parent: { key: string; value: string }[],
  filtered: DigestResponses.Investigation,
  level: number = 1,
) {
  return filtered.filter((elem) => {
    return (
      parent.every((p) =>
        elem.arr.some((c) => c.key === p.key && c.value === p.value),
      ) && (level == -1 ? true : elem.arr.length === level + parent.length)
    )
  })
}
