import {
  RenderTreeNodePayload,
  Stack,
  Tree,
  TreeNodeData,
  useTree,
  UseTreeReturnType,
} from "@mantine/core"
import { FC, MouseEventHandler } from "react"

import {
  BaseDashboardNodeProps,
  BaseFolderNodeProps,
  BaseSavedViewNodeProps,
  FoldersResponses,
  NodeProps,
  TreeNodeType,
} from "@costory/types/endpoints/folders"

import { ROOT_FOLDER_ID } from "@costory/shared/const"

import { DnDContextProvider } from "@costory/front/components/FileExplorer/DnDContext"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import {
  prefetchFolderContent,
  useFolderTree,
} from "@costory/front/queries/folders"
import { PropsWithData } from "@costory/front/utils/propsWithData"

type SelectableNodeProps<NP extends NodeProps> = NP & {
  onSelect: MouseEventHandler<HTMLDivElement>
  onDoubleClick?: MouseEventHandler<HTMLDivElement>
}

interface DashboardLeafNodeData extends TreeNodeData {
  nodeProps: SelectableNodeProps<BaseDashboardNodeProps>
  children: undefined
}

interface SavedViewLeafNodeData extends TreeNodeData {
  nodeProps: SelectableNodeProps<BaseSavedViewNodeProps>
  children: undefined
}

interface FolderNodeNodeData extends TreeNodeData {
  nodeProps: SelectableNodeProps<BaseFolderNodeProps> & {
    onClickExpand: MouseEventHandler<HTMLButtonElement>
    shouldShowCreateFolderInput: boolean
    onCreateFolderInputBlur: () => void
    onCreateFolderInputConfirm: (name: string) => void
    isPending: boolean
  }
}

export interface DashboardLeafProps extends RenderTreeNodePayload {
  node: DashboardLeafNodeData
}
export interface SavedViewLeafProps extends RenderTreeNodePayload {
  node: SavedViewLeafNodeData
}
export interface FolderNodeProps extends RenderTreeNodePayload {
  node: FolderNodeNodeData
}

type _TreeExplorerProps = TreeExplorerProps &
  PropsWithData<FoldersResponses.GetFolderTree>

const _TreeExplorer: FC<_TreeExplorerProps> = ({
  data: folderStructure,
  renderDashboardLeaf,
  renderSavedViewLeaf,
  renderFolderNode,
  tree,
}) => {
  const defaultTree = useTree({
    initialExpandedState: { [ROOT_FOLDER_ID]: true },
  })

  return (
    <DnDContextProvider>
      <Stack px={16} py={8} styles={{ root: { overflowY: "auto" } }}>
        <Tree
          tree={tree || defaultTree}
          levelOffset={32}
          data={folderStructure}
          expandOnClick={false}
          renderNode={(payload) => {
            const {
              nodeProps: { type },
            } = payload.node as
              | SavedViewLeafNodeData
              | DashboardLeafNodeData
              | FolderNodeNodeData

            switch (type) {
              case TreeNodeType.SavedView:
                return renderSavedViewLeaf(payload as SavedViewLeafProps)
              case TreeNodeType.Dashboard:
                return renderDashboardLeaf(payload as DashboardLeafProps)
              case TreeNodeType.Folder: {
                const {
                  node: { nodeProps: folderNodeProps, ...folderNode },
                  ...folderPayload
                } = payload as FolderNodeProps

                return renderFolderNode({
                  ...folderPayload,
                  node: {
                    nodeProps: {
                      ...folderNodeProps,
                      onClickExpand: (e) => {
                        e.stopPropagation()
                        tree
                          ? tree.toggleExpanded(folderNode.value)
                          : defaultTree.toggleExpanded(folderNode.value)
                      },
                    },
                    ...folderNode,
                  },
                })
              }
            }
          }}
        />
      </Stack>
    </DnDContextProvider>
  )
}

interface TreeExplorerProps {
  renderFolderNode: (node: FolderNodeProps) => React.ReactNode
  renderDashboardLeaf: (node: DashboardLeafProps) => React.ReactNode
  renderSavedViewLeaf: (node: SavedViewLeafProps) => React.ReactNode
  tree?: UseTreeReturnType
}

export const TreeExplorer: FC<TreeExplorerProps> = (props) => (
  prefetchFolderContent(ROOT_FOLDER_ID),
  (
    <QueryWrapper query={useFolderTree()} allowEmptyArray>
      {({ data }) => <_TreeExplorer data={data} {...props} />}
    </QueryWrapper>
  )
)
