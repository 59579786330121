/**
 * Computes the given percentile for an array of numbers.
 *
 * @param {Array} arr - A list of numerical values.
 * @param {number} q - The percentile to compute (between 0 and 100).
 * @returns {number} The computed percentile value.
 */
export function percentile(arr: Array<number>, q: number): number {
  if (!arr || arr.length === 0) {
    throw new Error("The input array cannot be empty")
  }
  if (q < 0 || q > 100) {
    throw new Error("percentile value must be between 0 and 100")
  }

  // Sort the array
  const sortedArr = arr.slice().sort((a, b) => a - b)

  // Compute the rank index
  const rank = (q / 100) * (sortedArr.length - 1)
  const lowerIndex = Math.floor(rank)
  const upperIndex = lowerIndex + 1
  const weight = rank - lowerIndex

  if (upperIndex >= sortedArr.length) {
    return sortedArr[lowerIndex]
  }

  // Linear interpolation between the two closest ranks
  return (1 - weight) * sortedArr[lowerIndex] + weight * sortedArr[upperIndex]
}
