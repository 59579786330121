import { useUser } from "@clerk/clerk-react"
import { Button, Checkbox, Group, Stack } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconRefresh } from "@tabler/icons-react"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import { LabelsExplorerResponses } from "@costory/types/endpoints/labelsExplorer"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { NumberCell } from "@costory/front/components/table/NumberCell"
import { apiClient } from "@costory/front/lib/apiClient"
import { LabelsExplorerModal } from "@costory/front/pages/LabelsExplorer/labelExplorerModal"
import {
  useLabelsExplorerMutation,
  useLabelsExplorerQuery,
} from "@costory/front/queries/labelsExplorer"
import { getDefaultTableOptions } from "@costory/front/utils/table"

type Props = {
  labelsExplorer: LabelsExplorerResponses.LabelsExplorer[]
}

const LabelsExplorer = ({ labelsExplorer }: Props) => {
  const [labelExplorer, setLabelExplorer] =
    useState<LabelsExplorerResponses.LabelsExplorer>()
  const {
    mutateAsync: UpdateLabelExplorer,
    isPending: isUpdatingLabelExplorer,
  } = useLabelsExplorerMutation()
  const [isOpen, { open, close }] = useDisclosure(false)
  const onSaveLabelExplorer = (
    data: LabelsExplorerResponses.LabelsExplorer,
  ) => {
    if (data) {
      UpdateLabelExplorer(data)
      close()
    }
  }

  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<LabelsExplorerResponses.LabelsExplorer>()
    return [
      columnHelper.accessor("key", {
        header: "Label",
      }),

      columnHelper.accessor("numberValues", {
        header: "Number Values",
        mantineTableBodyCellProps: {
          align: "center",
        },
      }),
      columnHelper.accessor("totalCostKeyPresentTotal", {
        header: "Total Cost Key Present",
        Cell: ({ cell }) => (
          <NumberCell value={Number(cell.getValue()) ?? 0} currency="USD" />
        ),
      }),
      columnHelper.accessor("description", {
        header: "Description",
      }),
      columnHelper.accessor("ingested", {
        header: "Ingested",
        Cell: ({ row }) => (
          <Checkbox
            checked={row.original.ingested}
            onChange={() => {}}
            disabled
          />
        ),
      }),
    ]
  }, [])

  const table = useMantineReactTable<LabelsExplorerResponses.LabelsExplorer>({
    columns,
    data: labelsExplorer,
    getRowId: (row) => row.key,
    initialState: {
      sorting: [{ id: "totalCostKeyPresentTotal", desc: true }],
    },
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => {
        setLabelExplorer(row.original)
        open()
      },
      style: {
        cursor: "pointer",
      },
    }),
    ...getDefaultTableOptions(true),
  })
  const navigate = useNavigate()
  const user = useUser()

  return (
    <Stack>
      <Group justify="flex-end">
        {user.isSignedIn &&
          user.user.emailAddresses[0].emailAddress.endsWith("costory.io") && (
            <Button
              variant="outline"
              leftSection={<IconRefresh />}
              onClick={() => apiClient.get("/labels-explorer/migrate")}
            >
              Import Labels
            </Button>
          )}

        <Button
          variant="outline"
          leftSection={<IconRefresh />}
          onClick={() => navigate("/setup-executions")}
        >
          Synchronize Labels
        </Button>
      </Group>

      <MantineReactTable table={table} />
      <LabelsExplorerModal
        data={labelExplorer}
        isOpen={isOpen}
        onClose={close}
        onSave={onSaveLabelExplorer}
        onSaving={isUpdatingLabelExplorer}
      />
    </Stack>
  )
}

export const LabelsExplorerPage = () => {
  const labelsExplorerData = useLabelsExplorerQuery()
  return (
    <QueryWrapper query={labelsExplorerData}>
      {({ data }) => <LabelsExplorer labelsExplorer={data} />}
    </QueryWrapper>
  )
}
