import { LineChart as BaseLineChart } from "@mantine/charts"

import { Filters } from "@costory/types/filters"

import { ChartTooltip } from "@costory/front/components/charts/ChartTooltip"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useExplorerQuery } from "@costory/front/queries/explore"
import { getCharteSerieColor, tickFormatter } from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"

type Props = {
  filters: Filters
  height?: number
  withLegend?: boolean
}

export const LineChart = ({ filters, height, withLegend = true }: Props) => {
  const explorerQuery = useExplorerQuery(filters)
  return (
    <QueryWrapper query={explorerQuery}>
      {({ data }) => (
        <BaseLineChart
          h={height ?? "100%"}
          pr="50px"
          data={data.stackedBarChartData.arr}
          xAxisProps={{
            angle: 30,
            height: 60,
            textAnchor: "start",
            tickFormatter: (val: string) => tickFormatter(val, filters.aggBy),
          }}
          dataKey="agg_date"
          withLegend={withLegend}
          curveType="linear"
          valueFormatter={(value) =>
            formatNumber(value, "currency", 2, filters.currency)
          }
          series={data.stackedBarChartData.items.map((item, index) => ({
            name: item.name,
            color: getCharteSerieColor(index),
          }))}
          tooltipProps={{
            content: ({ label, payload, coordinate }) => (
              <ChartTooltip
                label={label}
                payload={payload}
                currency={filters.currency}
                coordinate={{
                  x: coordinate?.x ?? 0,
                }}
                aggBy={filters.aggBy}
              />
            ),
          }}
        />
      )}
    </QueryWrapper>
  )
}
