import {
  ActionIcon,
  Group,
  Loader,
  Text,
  useComputedColorScheme,
} from "@mantine/core"
import { useHover } from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import {
  IconCaretDownFilled,
  IconCaretRightFilled,
  IconFolder,
} from "@tabler/icons-react"
import { MouseEventHandler, useEffect, useState } from "react"

import { ROOT_FOLDER_ID } from "@costory/shared/const"

import { useDnDContext } from "@costory/front/components/FileExplorer/DnDContext"
import { FolderNodeProps } from "@costory/front/components/FileExplorer/TreeExplorer"
import {
  prefetchFolderContent,
  useMoveFileMutation,
} from "@costory/front/queries/folders"

export const DefaultFolderNode = ({
  expanded,
  selected,
  node: {
    value,
    label,
    children,
    nodeProps: { onSelect, onDoubleClick, ...nodeProps },
  },
  elementProps,
}: FolderNodeProps) => {
  const { ref, hovered } = useHover()
  const colorScheme = useComputedColorScheme()
  const { draggedFile } = useDnDContext()
  const { mutateAsync: moveFile, isPending } = useMoveFileMutation()
  const [isDragHover, setIsDragHover] = useState(false)

  useEffect(() => {
    children?.map((child) => prefetchFolderContent(child.value))
  }, [children])

  const handleDoubleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation()
    onDoubleClick?.(e)
  }

  const folderNodeColorScheme = {
    dark: {
      root: {
        bg: selected ? "dark.6" : hovered ? "dark.7" : "transparent",
      },
      caret: {
        color: "dark.1",
      },
    },
    light: {
      root: {
        bg: selected ? "primary.1" : hovered ? "gray.1" : "transparent",
      },
      caret: {
        color: selected ? "gray.9" : hovered ? "gray.7" : "gray.5",
      },
    },
  } as const

  const handleDrop: React.DragEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault()
    if (!draggedFile) return

    try {
      await moveFile({
        fileId: draggedFile.id,
        type: draggedFile.type,
        folderId: value,
      })
      showNotification({
        title: "Success",
        message: "File moved successfully",
        color: "green",
      })
    } catch (e) {
      showNotification({
        title: "Error",
        message: "Failed to move file",
        color: "red",
      })
      console.error(e)
    }

    setIsDragHover(false)
  }

  return (
    <Group
      ref={ref}
      h={32}
      {...elementProps}
      justify="space-between"
      onClick={onSelect}
      onDoubleClick={handleDoubleClick}
      styles={{
        root: {
          borderRadius: 8,
        },
      }}
      bg={folderNodeColorScheme[colorScheme].root.bg}
      mb={4}
      bd={isDragHover ? "1px dashed black" : "none"}
      onDragOver={(e) => e.preventDefault()}
      onDragEnter={() => setIsDragHover(true)}
      onDragLeave={() => setIsDragHover(false)}
      onDrop={handleDrop}
    >
      <Group gap={2} justify="space-between">
        <Group gap={8}>
          <ActionIcon
            disabled={value === ROOT_FOLDER_ID}
            bg="transparent"
            onClick={nodeProps.onClickExpand}
            size="sm"
            color={folderNodeColorScheme[colorScheme].caret.color}
          >
            {isPending ? (
              <Loader size="sm" type="dots" />
            ) : expanded || nodeProps.shouldShowCreateFolderInput ? (
              <IconCaretDownFilled />
            ) : (
              <IconCaretRightFilled />
            )}
          </ActionIcon>
          <IconFolder size={20} />
        </Group>
        <Text mt={3} fw="bold">
          {label}
        </Text>
      </Group>
    </Group>
  )
}
