import { useMantineColorScheme } from "@mantine/core"
import { Outlet } from "react-router-dom"

interface ForceColorSchemeProps {
  colorScheme?: "light" | "dark"
}
export const ForceColorScheme = ({
  colorScheme = "light",
}: ForceColorSchemeProps) => {
  const { colorScheme: actualColorScheme, setColorScheme } =
    useMantineColorScheme()

  if (actualColorScheme !== colorScheme) {
    setColorScheme(colorScheme)
  }
  return <Outlet />
}
