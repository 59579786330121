import { showNotification } from "@mantine/notifications"
import { useNavigate } from "react-router-dom"

import { useCreateAlertMutation } from "@costory/front/queries/alerts"

import { AlertForm, AlertFormData } from "./AlertForm"

export const AlertCreationPage = () => {
  const { mutateAsync: createAlert } = useCreateAlertMutation()
  const navigate = useNavigate()

  const onSave = async (values: AlertFormData) => {
    try {
      await createAlert(values)
      showNotification({ message: "Alert created", color: "green" })
      navigate("..")
    } catch {
      showNotification({ message: "An error occurred", color: "red" })
    }
  }

  return <AlertForm initialValues={{}} onSave={onSave} />
}
