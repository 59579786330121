import { ActionIcon, Button, Group, Stack } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { modals } from "@mantine/modals"
import { IconEdit, IconPlus, IconTrash } from "@tabler/icons-react"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useCallback, useMemo, useState } from "react"

import { DatasourcesResponses } from "@costory/types/endpoints/datasources"

import { DEFAULT_DATE_FORMAT } from "@costory/shared/const"
import dayjs from "@costory/shared/dayjs"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { EditCreateModal } from "@costory/front/pages/Datasources/components/EditCreateModal"
import {
  useCreateDatasourceMutation,
  useDeleteDatasourceMutation,
  useListDatasourcesQuery,
  useUpdateDatasourceMutation,
} from "@costory/front/queries/datasources"
import { getDefaultTableOptions } from "@costory/front/utils/table"

type Props = {
  datasources: DatasourcesResponses.Datasource[]
}

const Datasource = ({ datasources }: Props) => {
  const [isOpen, { open, close }] = useDisclosure(false)

  const handleEdit = (row: DatasourcesResponses.Datasource) => {
    setSelectedRow(row)
    open()
  }

  const { mutateAsync: createDatasource, isPending: isCreating } =
    useCreateDatasourceMutation(close)
  const { mutateAsync: deleteDatasource, isPending: isDeleting } =
    useDeleteDatasourceMutation()
  const [selectedRow, setSelectedRow] =
    useState<DatasourcesResponses.Datasource>()
  const openDeleteModal = useCallback(
    (toDelete: DatasourcesResponses.Datasource) =>
      modals.openConfirmModal({
        title: "Please confirm you want to delete " + toDelete.name,
        labels: { confirm: "Confirm", cancel: "Cancel" },
        onConfirm: () => deleteDatasource(toDelete!.id),
      }),
    [deleteDatasource],
  )
  //const navigate = useNavigate()
  const { mutateAsync: updateDatasource, isPending: isUpdating } =
    useUpdateDatasourceMutation(selectedRow?.id ?? "new", close)

  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<DatasourcesResponses.Datasource>()
    return [
      columnHelper.accessor("name", {
        header: "Datasource",
        filterVariant: "select",
        Cell: ({ row }) =>
          row.original.connector === "GSheet" ||
          row.original.connector === "GSheetCurrency" ? (
            <a
              href={row.original.metadata.urlGooglesheet}
              target="_blank"
              rel="noreferrer"
            >
              {row.original.name}
            </a>
          ) : (
            <>{row.original.name}</>
          ),
      }),
      columnHelper.accessor("type", {
        header: "Type",
        filterVariant: "select",
      }),
      columnHelper.accessor("connector", {
        header: "Connector",
        filterVariant: "select",
      }),
      columnHelper.accessor("owner", {
        header: "Owner",
        filterVariant: "select",
      }),
      columnHelper.accessor("updatedAt", {
        header: "Last Update",
        Cell: ({ cell }) =>
          dayjs(cell.getValue()).local().format(DEFAULT_DATE_FORMAT),
      }),
    ]
  }, [])

  const table = useMantineReactTable<DatasourcesResponses.Datasource>({
    columns,
    data: datasources,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => (
      <>
        <ActionIcon
          onClick={() => handleEdit(row.original)}
          disabled={row.original.connector === "GSheet"}
        >
          <IconEdit />
        </ActionIcon>
        <ActionIcon
          onClick={() => {
            openDeleteModal(row.original)
          }}
          loading={isDeleting}
        >
          <IconTrash />
        </ActionIcon>
      </>
    ),
    ...getDefaultTableOptions(),
  })

  return (
    <Stack>
      <Group justify="flex-end">
        <Button
          onClick={() => {
            setSelectedRow(undefined)
            open()
          }}
          leftSection={<IconPlus />}
        >
          New
        </Button>
      </Group>

      <MantineReactTable table={table} />
      {isOpen && (
        <EditCreateModal
          datasource={selectedRow}
          onSave={selectedRow ? updateDatasource : createDatasource}
          isSaving={selectedRow ? isUpdating : isCreating}
          opened
          onClose={close}
        />
      )}
    </Stack>
  )
}

export const DatasourcesPage = () => {
  const datasourceQuery = useListDatasourcesQuery()

  return (
    <QueryWrapper query={datasourceQuery} allowEmptyArray>
      {({ data }) => <Datasource datasources={data} />}
    </QueryWrapper>
  )
}
