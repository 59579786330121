import {
  Group,
  Stack,
  Text,
  ThemeIcon,
  Title,
  useComputedColorScheme,
} from "@mantine/core"
import { useHover } from "@mantine/hooks"
import { IconListSearch } from "@tabler/icons-react"
import { FC, MouseEventHandler, useState } from "react"

import {
  FoldersResponses,
  TreeNodeType,
} from "@costory/types/endpoints/folders"

import {
  FileListItem,
  FileListItemProps,
} from "@costory/front/components/FileExplorer/FileListItem"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useFoldersSearch } from "@costory/front/queries/folders"
import { PropsWithData } from "@costory/front/utils/propsWithData"

interface ItemRowProps extends FileListItemProps {
  selected: boolean
  onDoubleClick?: MouseEventHandler<HTMLDivElement>
  onSelect: (
    id: string,
    label: string,
    type: TreeNodeType,
    closestFolderId: string,
    ownerId: string,
  ) => void
}

const ItemRow = ({
  selected,
  onSelect,
  onDoubleClick,
  ...leafRowProps
}: ItemRowProps) => {
  const { ref, hovered } = useHover()
  const colorScheme = useComputedColorScheme()

  const handleDoubleClick: MouseEventHandler<HTMLDivElement> = (e) => {
    onDoubleClick?.(e)
  }

  const fileLeafColorScheme = {
    dark: {
      root: {
        bg: selected ? "dark.6" : hovered ? "dark.7" : "transparent",
      },
    },
    light: {
      root: {
        bg: selected ? "primary.1" : hovered ? "gray.1" : "transparent",
      },
    },
  } as const

  return (
    <Group
      h={32}
      ref={ref}
      mb={4}
      pl={16}
      onClick={() =>
        onSelect(
          leafRowProps.id,
          leafRowProps.label,
          leafRowProps.type,
          leafRowProps.type === TreeNodeType.Folder
            ? leafRowProps.id
            : leafRowProps.parentFolderId,
          leafRowProps.ownerId,
        )
      }
      onDoubleClick={handleDoubleClick}
      bg={fileLeafColorScheme[colorScheme].root.bg}
      styles={{
        root: {
          cursor: "default",
          borderRadius: 8,
        },
      }}
    >
      <FileListItem {...leafRowProps} />
    </Group>
  )
}

interface _SearchExplorerProps {
  onSelect: (
    id: string,
    label: string,
    type: TreeNodeType,
    closestFolderId: string,
    ownerId: string,
  ) => void
  shouldShowDashboards: boolean
  shouldShowSavedViews: boolean
}

const _SearchExplorer: FC<
  _SearchExplorerProps & PropsWithData<FoldersResponses.Search>
> = ({ data, onSelect, shouldShowDashboards, shouldShowSavedViews }) => {
  const [selectedId, setSelectedId] = useState<string | null>(null)

  const handleSelect = (
    itemId: string,
    label: string,
    type: TreeNodeType,
    closestFolderId: string,
    ownerId: string,
  ) => {
    setSelectedId((prev) => (itemId === prev ? null : itemId))
    onSelect(itemId, label, type, closestFolderId, ownerId)
  }

  const filteredData = data
    .filter(
      (item) => shouldShowDashboards || item.type !== TreeNodeType.Dashboard,
    )
    .filter(
      (item) => shouldShowSavedViews || item.type !== TreeNodeType.SavedView,
    )

  return (
    <Stack p={8} gap={2}>
      {filteredData.length === 0 ? (
        <Stack align="center" py={80}>
          <ThemeIcon size={70}>
            <IconListSearch size={70} />
          </ThemeIcon>
          <Title>We found no file matching your request</Title>
          <Text fz="xl">Have you tried other keywords ?</Text>
        </Stack>
      ) : (
        filteredData.map((props) => (
          <ItemRow
            key={props.id}
            {...props}
            onSelect={handleSelect}
            selected={props.id === selectedId}
          />
        ))
      )}
    </Stack>
  )
}

interface SearchExplorerProps extends _SearchExplorerProps {
  search: string
}

export const SearchExplorer = ({
  search,
  ..._foldersSearchProps
}: SearchExplorerProps) => {
  const query = useFoldersSearch(search)
  return (
    <QueryWrapper query={query} allowEmptyArray>
      {({ data }) => <_SearchExplorer data={data} {..._foldersSearchProps} />}
    </QueryWrapper>
  )
}
