import { CHART_TYPES } from "@costory/types/endpoints/dashboard"

export type ChartType = (typeof CHART_TYPES)[number]

export const CHART_TYPE_WIDGET_CONFIG = {
  TREND: {
    isResizable: true,
    config: {
      minW: 2,
      maxW: Infinity,
      minH: 2,
      maxH: Infinity,
    },
    initial: {
      w: 3,
      h: 2,
    },
  },
  DONUT: {
    isResizable: false,
    config: {
      minW: 2,
      maxW: Infinity,
      minH: 2,
      maxH: Infinity,
    },
    initial: {
      w: 2,
      h: 2,
    },
  },
  WATERFALL: {
    isResizable: true,
    config: {
      minW: 2,
      maxW: Infinity,
      minH: 2,
      maxH: Infinity,
    },
    initial: {
      w: 3,
      h: 2,
    },
  },
  KPI: {
    isResizable: true,
    config: {
      minW: 2,
      maxW: Infinity,
      minH: 1,
      maxH: Infinity,
    },
    initial: {
      w: 2,
      h: 1,
    },
  },
  KPI_BREAKDOWN: {
    isResizable: true,
    config: {
      minW: 2,
      maxW: Infinity,
      minH: 2,
      maxH: Infinity,
    },
    initial: {
      w: 2,
      h: 1.2,
    },
  },
}
