import { RangeSliderProps } from "@mantine/core"
import {
  HTMLPropsRef,
  MRT_RowData,
  MRT_TableOptions,
} from "mantine-react-table"

import { colors } from "@costory/front/assets/theme/colors"
import { formatNumber } from "@costory/front/utils/format"

export const getDefaultTableOptions = <T extends MRT_RowData>(
  enableTopToolbar: boolean = false,
): Partial<MRT_TableOptions<T>> => ({
  enableTopToolbar,
  enableColumnFilters: true,
  enableColumnFilterModes: false,
  enableFacetedValues: true,
  paginationDisplayMode: "pages",
  mantinePaperProps: {
    shadow: "none",
    style: {
      border: "1px solid",
      borderColor: colors.gray[3],
      borderRadius: 8,
    },
  },
  mantinePaginationProps: {
    showRowsPerPage: false,
  },
})

export const CURRENCY_FILTER_VARIANT: {
  filterVariant: "range-slider"
  mantineFilterRangeSliderProps: HTMLPropsRef<HTMLInputElement> &
    Partial<RangeSliderProps>
} = {
  filterVariant: "range-slider",
  mantineFilterRangeSliderProps: { label: formatNumber },
}
