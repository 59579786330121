import { showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"

import { TagsRequests } from "@costory/types/endpoints/tags"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export const useCreateTagsMutation = () => {
  return useMutation({
    mutationFn: async (data: TagsRequests.EditTag) => {
      const response = await apiClient.post("/tags", data)
      return response.data
    },
    onSuccess: async ({ name }) => {
      await queryClient.invalidateQueries({ queryKey: ["tags"] })
      showNotification({
        color: "green",
        title: "Tag created!",
        message: `Tag ${name} created successfully!`,
      })
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
}

export const useUpdateTagsMutation = () => {
  return useMutation({
    mutationFn: async (data: TagsRequests.EditTag) => {
      const response = await apiClient.post(`/tags/${data.id}`, data)
      return response.data
    },
    onSuccess: async ({ name }) => {
      await queryClient.invalidateQueries({ queryKey: ["tags"] })
      showNotification({
        color: "green",
        title: "Tag updated!",
        message: `Tag ${name} updated successfully!`,
      })
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
}

export const useDeleteTagsMutation = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await apiClient.delete(`tags/${id}`)
      return response.data
    },
    onSuccess: async ({ name }) => {
      await queryClient.invalidateQueries({ queryKey: ["tags"] })
      showNotification({
        color: "red",
        title: "Tag Deleted!",
        message: `Tag ${name} deleted successfully!`,
      })
    },
    onError: (error: { error: string }) => {
      showNotification({ color: "red", title: "Error", message: error.error })
    },
  })
}
