import { Checkbox, Group, Text } from "@mantine/core"

import { optionType } from "@costory/front/components/QueryBuilder"

interface IDefaultItemRendererProps {
  checked: boolean
  option: optionType
  disabled?: boolean
  onClick: () => void
}

export const OptionItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
}: IDefaultItemRendererProps) => (
  <Group gap="sm">
    <Checkbox
      checked={checked}
      tabIndex={-1}
      onChange={onClick}
      disabled={disabled}
    />
    <Text>{option.label}</Text>
  </Group>
)
