import { Menu, ActionIcon } from "@mantine/core"
import { IconSearch } from "@tabler/icons-react"

import { formatNumber } from "@costory/front/utils/format"

export const ButtonAddTo = ({
  options,
  addChild,
  mapper,
  total,
  disabled,
}: {
  options: { direction: string; nbrValue: number; totalDiff: number }[]
  addChild: (direction: string) => void
  mapper: Map<string, string>
  total?: number
  disabled: boolean
}) => {
  // What is the best suggestion ?
  const suggested = options.filter(
    (elem) =>
      elem.nbrValue > 1 && (total ? total * 0.7 <= elem.totalDiff : true),
  )
  const discouraged = options.filter(
    (elem) =>
      !(elem.nbrValue > 1 && (total ? total * 0.7 <= elem.totalDiff : true)),
  )

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <ActionIcon
          variant="transparent"
          aria-label="Settings"
          disabled={disabled}
        >
          <IconSearch style={{ width: "70%", height: "70%" }} stroke={1.5} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label> Suggested </Menu.Label>
        {suggested.map((elem) => (
          <Menu.Item
            onClick={() => addChild(elem.direction)}
            key={elem.direction}
          >
            {mapper.get(elem.direction) || elem.direction}: {elem.nbrValue} :
            {formatNumber(elem.totalDiff)}
          </Menu.Item>
        ))}
        <Menu.Label> Other axes </Menu.Label>
        {discouraged.map((elem) => (
          <Menu.Item
            onClick={() => addChild(elem.direction)}
            key={elem.direction}
          >
            {mapper.get(elem.direction) || elem.direction}: {elem.nbrValue} :
            {formatNumber(elem.totalDiff)}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  )
}
