import { Modal } from "@mantine/core"

import { DatasourcesResponses } from "@costory/types/endpoints/datasources"
import {
  MetricRequests,
  MetricsResponses,
} from "@costory/types/endpoints/metrics"

import { MetricForm } from "@costory/front/pages/Metrics/components/MetricForm"

type Props = {
  datasources: DatasourcesResponses.Datasource[]
  selectedMetric?: MetricsResponses.Metric
  isSaving: boolean
  onSave: (data: MetricRequests.EditMetric) => void
  close: () => void
  isOpen: boolean
}
export const EditCreateModalMetric = ({
  datasources,
  selectedMetric,
  isSaving,
  onSave,
  close,
  isOpen,
}: Props) => {
  return (
    <Modal
      size="xl"
      opened={isOpen}
      onClose={close}
      title={selectedMetric ? "Edit Metric" : "Create Metric"}
    >
      <MetricForm
        datasources={datasources}
        onSave={onSave}
        isSaving={isSaving}
        selectedMetric={selectedMetric}
        close={close}
        isOpen={false}
      />
    </Modal>
  )
}
