import { DashboardLayout } from "@costory/front/components/dashboard/DashboardLayout"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useListSavedViewsQuery } from "@costory/front/queries/savedViews"

export const DashboardCreationPage = () => {
  const savedViewQuery = useListSavedViewsQuery()

  return (
    <QueryWrapper query={savedViewQuery} allowEmptyArray>
      {({ data: savedViews }) => <DashboardLayout savedViews={savedViews} />}
    </QueryWrapper>
  )
}
