import { Accordion, Center, Group, Select, Tooltip } from "@mantine/core"
import {
  IconBrandSlack,
  IconPlugConnected,
  IconPlugConnectedX,
} from "@tabler/icons-react"
import { useQuery } from "@tanstack/react-query"

import { IntegrationResponses } from "@costory/types/endpoints/integration"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { SlackButton } from "@costory/front/pages/Integration/components/SlackButton"

export const SlackIntegration = ({
  data,
}: {
  data: IntegrationResponses.slackCheck
}) => {
  return (
    <div>
      <h4>Connect Costory to your tools</h4>
      <Accordion chevronPosition="left">
        <Accordion.Item key="slack" value="slack">
          <Accordion.Control
            icon={
              data.channels ? (
                <Tooltip label="Connected">
                  <Center>
                    <IconPlugConnected />{" "}
                  </Center>
                </Tooltip>
              ) : (
                <Tooltip label="Not Connected">
                  <Center>
                    <IconPlugConnectedX />{" "}
                  </Center>
                </Tooltip>
              )
            }
          >
            <IconBrandSlack /> Slack
          </Accordion.Control>
          <Accordion.Panel>
            {data.channels ? (
              <Group>
                List Available Channels
                <Select
                  placeholder="Channels"
                  data={data.channels}
                  searchable
                />
              </Group>
            ) : (
              <SlackButton url={data.url!} />
            )}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export const IntegrationPage = () => {
  const slackQuery = useQuery({
    queryKey: ["slack_check"],
    queryFn: async () => {
      const response =
        await apiClient.get<IntegrationResponses.slackCheck>("/slack/check")
      return response.data
    },
  })

  return (
    <QueryWrapper query={slackQuery}>
      {({ data }) => <SlackIntegration data={data} />}
    </QueryWrapper>
  )
}
