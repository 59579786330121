import {
  Button,
  Group,
  Input,
  Modal,
  Paper,
  SegmentedControl,
  Stack,
  Text,
  ThemeIcon,
  Tooltip,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { useDisclosure, useUncontrolled } from "@mantine/hooks"
import {
  IconCloudSearch,
  IconExclamationCircle,
  IconPencil,
} from "@tabler/icons-react"
import _ from "lodash"
import { useMemo } from "react"

import { VirtualDimensionsRequests } from "@costory/types/endpoints/virtualDimensions"

import { SelectCreatable } from "@costory/front/components/SelectCreatable"
import SplitCostResult from "@costory/front/pages/VirtualDimensions/components/SplitCostResult"
import { ALLOCATION_TYPES } from "@costory/front/pages/VirtualDimensions/constants"
import { formatNumber } from "@costory/front/utils/format"
import { showError } from "@costory/front/utils/validation"

type Props = {
  form: UseFormReturnType<VirtualDimensionsRequests.EditVirtualDimension>
  allocatedCost: number | undefined
  exploreCost: () => void
}

export const Leftovers = ({ form, allocatedCost, exploreCost }: Props) => {
  const [isOpen, { open, close }] = useDisclosure()

  const hasError = useMemo(() => {
    const formErrorsKeys = Object.keys(form.errors)
    return formErrorsKeys.some((key) => key.includes("leftoverRule"))
  }, [form.errors])
  const allocation = form.getValues().leftoverRule.allocation

  const {
    value: dimentionValue,
    defaultValue,
    onChange,
  } = form.getInputProps("leftoverRule.allocation.dimensionValue")

  const [value, setValue] = useUncontrolled({
    value: dimentionValue,
    defaultValue,
    finalValue: "Other",
    onChange,
  })

  return (
    <>
      <Paper>
        <Stack p={16}>
          <Group justify="space-between">
            <Group gap={4}>
              {hasError && (
                <Tooltip
                  label={
                    <>
                      {showError({
                        prefix: "leftover",
                        errors: form.errors,
                      }).join(",")}
                    </>
                  }
                >
                  <ThemeIcon c="red.5">
                    <IconExclamationCircle />
                  </ThemeIcon>
                </Tooltip>
              )}

              <Text size="lg" fw="bold" c={hasError ? "red.5" : "inherit"}>
                {form.getValues().leftoverRule.name}
              </Text>
            </Group>
            <Group>
              {allocatedCost ? formatNumber(allocatedCost, "currency") : ""}
              <Button
                size="xs"
                color="gray"
                variant="outline"
                leftSection={<IconPencil size={16} />}
                onClick={open}
              >
                Rename rule
              </Button>
            </Group>
          </Group>

          <Group justify="space-between">
            <Group>
              <SegmentedControl
                data={ALLOCATION_TYPES.filter(
                  (a) => a.value !== "existingColumn",
                )}
                key={form.key("leftoverRule.allocation.allocationType")}
                {...form.getInputProps(
                  "leftoverRule.allocation.allocationType",
                )}
                onChange={(val) => {
                  switch (val) {
                    case "splitCost": {
                      form.setFieldValue("leftoverRule.allocation", {
                        allocationType: "splitCost",
                        reAllocationParams: { partitions: [], type: "custom" },
                      })
                      form.validate()
                      //openSplitCost()
                      break
                    }
                    case "existingColumn": {
                      form.setFieldValue("leftoverRule.allocation", {
                        allocationType: "existingColumn",
                        existingColumn: null as unknown as string,
                      })
                      form.validate()

                      break
                    }

                    case "dimensionValue": {
                      form.setFieldValue("leftoverRule.allocation", {
                        allocationType: "dimensionValue",
                        dimensionValue: defaultValue,
                      })
                      form.validate()

                      break
                    }
                  }
                }}
              />
              {form.getValues().leftoverRule.allocation.allocationType ==
                "dimensionValue" && (
                <SelectCreatable
                  key={form.key("leftoverRule.allocation.dimensionValue")}
                  data={form.getValues().values}
                  {...form.getInputProps(
                    "leftoverRule.allocation.dimensionValue",
                  )}
                  handleCreate={(val) => form.insertListItem("values", val)}
                  value={value}
                  onChange={setValue}
                  error={
                    form.getInputProps("leftoverRule.allocation.dimensionValue")
                      .error !== undefined
                  }
                />
              )}
            </Group>
            <Button
              variant="outline"
              size="sm"
              leftSection={<IconCloudSearch />}
              onClick={exploreCost}
            >
              Explore leftovers costs
            </Button>
          </Group>
          <SplitCostResult
            allocation={allocation}
            prefixForm="leftoverRule.allocation"
            form={form}
            costRule={allocatedCost || 0}
            vdDict={_.uniq(form.getValues().values)}
          />
        </Stack>
        <Modal
          opened={isOpen}
          onClose={close}
          title="Rename a rule"
          withinPortal
        >
          <Group>
            <Input
              {...form.getInputProps("leftoverRule.name")}
              flex={1}
              onKeyDown={(key) => (key.key == "Enter" ? close() : null)}
            />
            <Button onClick={close}>Save</Button>
          </Group>
        </Modal>
      </Paper>
    </>
  )
}
