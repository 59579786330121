import { Stack } from "@mantine/core"
import { useNavigate } from "react-router-dom"

import { TreeNodeType } from "@costory/types/endpoints/folders"

import { FileExplorer } from "@costory/front/components/FileExplorer/FileExplorer"

export const ReportsPage = () => {
  const navigate = useNavigate()

  const onOpenDashboard = (id: string) => {
    navigate("/dashboards/" + id)
  }

  const onOpenSavedView = (id: string) => {
    navigate("/explorer/views/" + id)
  }

  const handleDoubleClick = (id: string, type: TreeNodeType) => {
    switch (type) {
      case TreeNodeType.Dashboard:
        onOpenDashboard(id)
        break
      case TreeNodeType.SavedView:
        onOpenSavedView(id)
        break
      default:
        break
    }
  }

  return (
    <Stack h="80vh">
      <FileExplorer
        optionNewDashboardButton
        onDoubleClick={handleDoubleClick}
        onOpenDashboard={onOpenDashboard}
        onOpenSavedView={onOpenSavedView}
        buttonProps={{ label: "Go to selected" }}
      />
    </Stack>
  )
}
