import { BarChart, GridChartBaseProps } from "@mantine/charts"

import { Filters } from "@costory/types/filters"

import { ChartTooltip } from "@costory/front/components/charts/ChartTooltip"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { useExplorerQuery } from "@costory/front/queries/explore"
import { getCharteSerieColor, tickFormatter } from "@costory/front/utils/charts"
import { formatNumber } from "@costory/front/utils/format"

type Props = {
  filters: Filters
  height?: number
  withLegend?: boolean
  drillDownInto?: (value: string) => void
  referenceLines?: GridChartBaseProps["referenceLines"]
}

export const StackedBarChart = ({
  filters,
  height,
  withLegend = true,
  drillDownInto,
  referenceLines,
}: Props) => {
  const explorerQuery = useExplorerQuery(filters)
  return (
    <QueryWrapper query={explorerQuery}>
      {({ data }) => (
        <BarChart
          h={height ?? "100%"}
          pr="50px"
          data={data.stackedBarChartData.arr}
          dataKey="agg_date"
          type="stacked"
          withLegend={withLegend}
          xAxisProps={{
            angle: 30,
            height: 60,
            textAnchor: "start",
            tickFormatter: (val: string) => tickFormatter(val, filters.aggBy),
          }}
          tooltipProps={{
            content: ({ label, payload, coordinate }) => (
              <ChartTooltip
                label={label}
                payload={payload}
                currency={filters.currency}
                coordinate={{
                  x: coordinate?.x ?? 0,
                }}
                aggBy={filters.aggBy}
              />
            ),
          }}
          valueFormatter={(value) =>
            formatNumber(value, "currency", 2, filters.currency)
          }
          series={data.stackedBarChartData.items.map((item, index) => ({
            name: item.name,
            color: getCharteSerieColor(index),
          }))}
          barChartProps={{ stackOffset: "sign" }}
          barProps={{
            // copy paste of Tremor approach to know which bar was clicked...
            onClick: (data) =>
              drillDownInto && drillDownInto(data.tooltipPayload[0].dataKey),
          }}
          referenceLines={referenceLines}
        />
      )}
    </QueryWrapper>
  )
}
