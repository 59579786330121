import { AxiosResponse } from "axios"

import {
  FoldersRequests,
  FoldersResponses,
} from "@costory/types/endpoints/folders"

import { apiClient } from "@costory/front/lib/apiClient"

// Fetch all folders
export const fetchFolders = async () => {
  const response =
    await apiClient.get<FoldersResponses.GetAllFolders>("/folders")
  return response.data
}

export const fetchFolderContent = async (id: string) => {
  const response = await apiClient.get<FoldersResponses.GetFolderContent>(
    `/folders/${id}/content`,
  )
  return response.data
}

// Fetch the nested structure of folders and saved views
export const fetchFolderTree = async () => {
  const response =
    await apiClient.get<FoldersResponses.GetFolderTree>("/folders/tree")
  return response.data
}

export const fetchFolderById = async (id: string) => {
  const response = await apiClient.get<FoldersResponses.GetFolder>(
    `/folders/${id}`,
  )
  return response.data
}

export const createFolder = async (newFolder: FoldersRequests.CreateFolder) => {
  const response = await apiClient.post<
    FoldersResponses.CreateFolder,
    AxiosResponse<FoldersResponses.CreateFolder>,
    FoldersRequests.CreateFolder
  >("/folders", newFolder)
  return response.data
}

export const updateFolder = async ({
  id,
  folder,
}: {
  id: string
  folder: FoldersRequests.UpdateFolder
}) => {
  const response = await apiClient.put<
    FoldersResponses.UpdateFolder,
    AxiosResponse<FoldersResponses.UpdateFolder>,
    FoldersRequests.UpdateFolder
  >(`/folders/${id}`, folder)
  return response.data
}

export const deleteFolder = async (id: string) => {
  const response = await apiClient.delete<FoldersResponses.DeleteFolder>(
    `/folders/${id}`,
  )
  return response.data
}

export const searchFolder = async (search: string) => {
  if (!search) {
    return []
  }
  const response = await apiClient.get<FoldersResponses.Search>(
    `/folders/search/${search}`,
  )
  return response.data
}

export const renameFolder = async ({
  id,
  name,
}: {
  id: string
  name: string
}) => {
  const response = await apiClient.put<FoldersResponses.RenameFolder>(
    `/folders/${id}`,
    { name },
  )
  return response.data
}

export const moveFile = async (
  id: string,
  type: "dashboard" | "savedView",
  parentFolderId: string,
) => {
  const route = type === "dashboard" ? "dashboard" : "saved-views"
  const response = await apiClient.post<
    FoldersResponses.MoveFile,
    AxiosResponse<FoldersResponses.MoveFile>,
    FoldersRequests.MoveFile
  >(`${route}/${id}/move`, { parentFolderId })

  return response.data
}
