import { Badge, Button, Group, Stack, Text, Title } from "@mantine/core"
import { IconEdit } from "@tabler/icons-react"
import dayjs from "dayjs"
import { Link, useParams } from "react-router-dom"

import { useUser } from "@costory/front/components/auth/UserContext"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { BudgetCSVExportDownloadButton } from "@costory/front/pages/Budgets/BudgetCSVExportButton"
import { BudgetTable } from "@costory/front/pages/Budgets/BudgetTable"
import { useGetBudgetQuery } from "@costory/front/queries/budgets"

export const BudgetPage = () => {
  const { budgetId } = useParams<{ budgetId: string }>()
  const query = useGetBudgetQuery(budgetId!)
  const user = useUser()

  return (
    <QueryWrapper query={query}>
      {({ data: { budget, availableBusinessMetrics, availableOwners } }) => {
        const startDate = dayjs()
          .year(budget.year)
          .month(budget.startMonth)
          .startOf("month")
          .add(1, "day")
          .toDate()

        return (
          <Stack>
            <Group>
              <Group align="end">
                <Title order={2}>
                  {budget.name} - {dayjs(startDate).format("MMMM YYYY")} /{" "}
                  {dayjs(startDate).add(11, "month").format("MMMM YYYY")}
                </Title>
                {/* @ts-expect-error computed property... */}
                <Text>Owned by: {budget.createdBy.fullName}</Text>
              </Group>
              {budget.tags.map((tag) => (
                <Badge key={tag.name} color={tag.color}>
                  {tag.name}
                </Badge>
              ))}
            </Group>
            <BudgetTable
              readonly
              lines={budget.lines}
              currency={budget.currency}
              virtualDimension={budget.virtualDimension}
              availableBusinessMetrics={availableBusinessMetrics}
              availableOwners={availableOwners}
              startDate={startDate}
            />

            <Group justify="end">
              <BudgetCSVExportDownloadButton budgetId={budgetId!} />
              {budget.createdBy.id === user.id && (
                <Button component={Link} to="edit" leftSection={<IconEdit />}>
                  Edit
                </Button>
              )}
            </Group>
          </Stack>
        )
      }}
    </QueryWrapper>
  )
}
