import { Group, Paper, Stack, Text, ThemeIcon } from "@mantine/core"
import {
  Icon123,
  IconChartArrowsVertical,
  IconChartBar,
  IconChartDonut4,
  IconListNumbers,
  IconProps,
} from "@tabler/icons-react"
import { ForwardRefExoticComponent } from "react"

import { ChartType } from "./constants"

type ChartTypeInfo = {
  key: ChartType
  name: string
  icon: ForwardRefExoticComponent<Omit<IconProps, "ref">>
}

const CHART_TYPE_INFOS: ChartTypeInfo[] = [
  { key: "TREND", name: "Trend", icon: IconChartBar },
  { key: "DONUT", name: "Donut", icon: IconChartDonut4 },
  { key: "WATERFALL", name: "Waterfall", icon: IconChartArrowsVertical },
  { key: "KPI", name: "KPI", icon: Icon123 },
  { key: "KPI_BREAKDOWN", name: "KPI breakdown", icon: IconListNumbers },
] as const

type CardProps = {
  isSelected: boolean
  chartType: ChartTypeInfo
  onClick: () => void
}

const ChartTypeCard = ({ chartType, isSelected, onClick }: CardProps) => {
  return (
    <Paper
      onClick={onClick}
      w={140}
      h={140}
      p={8}
      style={({ colors }) => ({
        cursor: "pointer",
        borderWidth: isSelected ? 2 : 1,
        borderColor: isSelected ? colors.primary[7] : colors.gray[4],
      })}
    >
      <Stack align="center" justify="center" h="100%">
        <ThemeIcon size={55} color={isSelected ? "primary.7" : "primary.4"}>
          <chartType.icon size={55} strokeWidth={1.5} />
        </ThemeIcon>
        <Text
          fz="sm"
          ta="center"
          fw="bold"
          c={isSelected ? "primary.7" : "inherit"}
        >
          {chartType.name}
        </Text>
      </Stack>
    </Paper>
  )
}

type Props = {
  value?: string
  onChange: (chartType: string) => void
}

export const ChartTypeSelector = ({ value, onChange }: Props) => {
  return (
    <Group>
      {CHART_TYPE_INFOS.map((chartType) => (
        <ChartTypeCard
          key={chartType.key}
          chartType={chartType}
          isSelected={value === chartType.key}
          onClick={() => onChange(chartType.key)}
        />
      ))}
    </Group>
  )
}
