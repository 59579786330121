import { memo } from "react"

import { Currency } from "@costory/types/prisma-client"

import { Td, TdProps } from "@costory/front/pages/Budgets/Td"
import { formatBudgetNumber } from "@costory/front/utils/format"

interface DeltaCellProps extends TdProps {
  delta: number | undefined
  currency: Currency
}

export const DeltaCell = memo(function DeltaCell({
  delta,
  currency,
  ...props
}: DeltaCellProps) {
  if (delta === undefined) {
    return (
      <Td {...props} ref={null}>
        -
      </Td>
    )
  }

  const formattedValue = formatBudgetNumber(delta, true, currency)
  return (
    <Td
      {...props}
      style={{
        color:
          delta <= 0
            ? "var(--mantine-color-green-6)"
            : "var(--mantine-color-red-6)",
        ...props.style,
      }}
      ref={null}
    >
      {formattedValue}
    </Td>
  )
})
