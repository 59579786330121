import { useParams } from "react-router-dom"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { VirtualDimensionForm } from "@costory/front/pages/VirtualDimensions/components/VirtualDimensionForm"
import {
  useCreateDraftVirtualDimensionMutation,
  useUpdateVirtualDimensionMutation,
  useVirtualDimensionQuery,
} from "@costory/front/queries/virtualDimensions"

export const EditVirtualDimensionPage = () => {
  const params = useParams()
  const virtualDimensionId = params.virtualDimensionId as string
  const virtualDimensionQuery = useVirtualDimensionQuery(virtualDimensionId)

  const { mutateAsync: updateVirtualDimension, isPending } =
    useUpdateVirtualDimensionMutation(virtualDimensionId)
  const {
    mutateAsync: createDraftVirtualDimension,
    isPending: isPendingDraftVirtualDimensions,
  } = useCreateDraftVirtualDimensionMutation()

  return (
    <QueryWrapper query={virtualDimensionQuery}>
      {({ data }) => (
        <VirtualDimensionForm
          inputData={{ data, type: "vdim" }}
          onSave={updateVirtualDimension}
          isSaving={isPending}
          isSavingDraft={isPendingDraftVirtualDimensions}
          onSaveDraft={createDraftVirtualDimension}
        />
      )}
    </QueryWrapper>
  )
}
