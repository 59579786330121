import { Modal, Title } from "@mantine/core"

import { TagsRequests, TagsResponses } from "@costory/types/endpoints/tags"

import { TagForm } from "@costory/front/pages/Tags/components/TagForm"

type Props = {
  selectedTag?: TagsResponses.Tags
  isSaving: boolean
  onSave: (data: TagsRequests.EditTag) => void
  close: () => void
  isOpen: boolean
}
export const EditCreateTag = ({
  selectedTag,
  isSaving,
  onSave,
  close,
  isOpen,
}: Props) => {
  return (
    <Modal
      size="sm"
      opened={isOpen}
      onClose={close}
      title={<Title size="lg">{selectedTag ? "Edit Tag" : "Create Tag"}</Title>}
    >
      <TagForm
        isSaving={isSaving}
        selectedTag={selectedTag}
        onSave={onSave}
        close={close}
        isOpen={isOpen}
      />
    </Modal>
  )
}
