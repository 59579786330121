import { Button, Modal, Stack, Text, ThemeIcon, Title } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconLayoutOff, IconPlus, IconSettings } from "@tabler/icons-react"
import { Link } from "react-router-dom"

import { TreeNodeType } from "@costory/types/endpoints/folders"

import { FileExplorer } from "@costory/front/components/FileExplorer/FileExplorer"
import { useSetDefaultDashboardMutation } from "@costory/front/queries/dashboard"

export const NoDefaultDashboard = () => {
  const {
    mutateAsync: setDefaultDashboard,
    isPending: isSetDefaultDashboardMutationPending,
  } = useSetDefaultDashboardMutation()

  const [
    isDefaultDashboardPickerModalOpen,
    {
      open: openDefaultDashboardPickerModal,
      close: closeDefaultDashboardPickerModal,
    },
  ] = useDisclosure()

  const handleOpenDashboard = async (dashboardId: string) => {
    await setDefaultDashboard(dashboardId)
    closeDefaultDashboardPickerModal()
  }

  return (
    <Stack align="center" py={40}>
      <ThemeIcon size={70}>
        <IconLayoutOff size={70} />
      </ThemeIcon>
      <Title>You haven&lsquo;t set a default dashboard</Title>
      <Text fz="xl">
        Start exploring your data, create views and add widgets!
      </Text>
      <Stack align="stretch" gap={8}>
        <Button
          onClick={openDefaultDashboardPickerModal}
          leftSection={<IconSettings />}
        >
          Set a default dashboard
        </Button>
        <Text styles={{ root: { alignSelf: "center" } }}>or</Text>
        <Button
          component={Link}
          to="/dashboards/new"
          leftSection={<IconPlus />}
          variant="outline"
        >
          Create a new dashboard
        </Button>
      </Stack>
      <Modal
        opened={isDefaultDashboardPickerModalOpen}
        onClose={closeDefaultDashboardPickerModal}
        title={<Title>Select a default dashboard</Title>}
        size="70vw"
        styles={{ body: { height: "70vh" } }}
      >
        <FileExplorer
          onDoubleClick={(id, type) => {
            if (type !== TreeNodeType.Dashboard) {
              return
            }
            handleOpenDashboard(id)
          }}
          onOpenDashboard={handleOpenDashboard}
          defaultShouldShowSavedViews={false}
          buttonProps={{
            label: "Set as default",
            loading: isSetDefaultDashboardMutationPending,
          }}
        />
      </Modal>
    </Stack>
  )
}
