import { Badge } from "@mantine/core"
import { useMemo } from "react"

const DATA_HEALTH_STATUS = {
  LIVE: { label: "Live", color: "green" },
  SETUP: { label: "Setup", color: "orange" },
}

type Props = {
  status: keyof typeof DATA_HEALTH_STATUS
}

export const DataHealthStatusBadgeCell = ({ status }: Props) => {
  const badgeProps = useMemo(() => DATA_HEALTH_STATUS[status], [status])
  return <Badge color={badgeProps.color}>{badgeProps.label}</Badge>
}
