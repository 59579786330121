import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"

import {
  DatasourcesRequests,
  DatasourcesResponses,
} from "@costory/types/endpoints/datasources"

import { apiClient } from "@costory/front/lib/apiClient"
import { queryClient } from "@costory/front/lib/queryClient"

export const useListDatasourcesQuery = () => {
  return useQuery({
    queryKey: ["datasources"],
    queryFn: async () => {
      const response =
        await apiClient.get<DatasourcesResponses.Datasource[]>("/datasources")
      return response.data
    },
  })
}

export const useDatasourceQuery = (id: string) => {
  return useQuery({
    queryKey: ["datasources", id],
    queryFn: async () => {
      const response = await apiClient.get<DatasourcesResponses.Datasource>(
        `/datasources/${id}`,
      )
      return response.data
    },
  })
}

export const useCreateDatasourceMutation = (onSuccess: () => void) => {
  return useMutation({
    mutationFn: async (data: DatasourcesRequests.EditDatasource) => {
      const response = await apiClient.post("/datasources", data)
      return response.data
    },
    onSuccess: async () => {
      // TODO: better messages
      onSuccess()
      await queryClient.invalidateQueries({ queryKey: ["datasources"] })
      showNotification({ color: "green", title: "Success", message: "Saved" })
    },
    onError: (error) => {
      showNotification({ color: "red", title: "Error", message: error.message })
    },
  })
}

export const useUpdateDatasourceMutation = (
  id: string,
  onSuccess: () => void,
) => {
  return useMutation({
    mutationFn: async (data: DatasourcesRequests.EditDatasource) => {
      const response = await apiClient.put(`/datasources/${id}`, data)
      return response.data
    },
    onSuccess: async () => {
      // TODO: better messages
      onSuccess()
      await queryClient.invalidateQueries({ queryKey: ["datasources"] })
      showNotification({ color: "green", title: "Success", message: "Saved" })
    },
    onError: (error) => {
      showNotification({ color: "red", title: "Error", message: error.message })
    },
  })
}

export const useDeleteDatasourceMutation = () => {
  return useMutation({
    mutationFn: async (id: string) => {
      const response = await apiClient.delete(`/datasources/${id}`)
      return response.data
    },
    onSuccess: async () => {
      // TODO: better messages
      await queryClient.invalidateQueries({ queryKey: ["datasources"] })
      showNotification({
        color: "green",
        title: "Success",
        message: "Archived",
      })
    },
    onError: (error) => {
      showNotification({ color: "red", title: "Error", message: error.message })
    },
  })
}

export const useDatasourceBusinessMetricsValuesQuery = (
  id: string | undefined,
) => {
  return useQuery({
    queryKey: ["datasources", "businessMetricsValues", id],
    queryFn: async () => {
      if (!id) return null
      const response =
        await apiClient.get<DatasourcesResponses.GetBusinessMetricsValues>(
          `/datasources/${id}/business-metrics-values`,
        )
      return response.data
    },
  })
}

export const useTelemetryOptionsQuery = () => {
  return useQuery({
    queryKey: ["datasources", "telemetryOptions"],
    queryFn: async () => {
      const response =
        await apiClient.get<DatasourcesResponses.GetTelemetryOptions>(
          "/datasources/telemetry-options",
        )
      return response.data
    },
  })
}
