import { MantineProvider } from "@mantine/core"
import { DatesProvider } from "@mantine/dates"
import { ModalsProvider } from "@mantine/modals"
import { Notifications } from "@mantine/notifications"
import * as Sentry from "@sentry/react"
import { extraErrorDataIntegration } from "@sentry/react"
import TagManager from "@sooro-io/react-gtm-module"
import ReactDOM from "react-dom/client"

import { theme } from "@costory/front/assets/theme/index"

import App from "./App"

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_ID,
  source: "https://metrics.costory.io/gtm.js",
}

//Move below after tests
TagManager.initialize(tagManagerArgs)
if (import.meta.env.VITE_ENVIRONMENT !== "development") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      extraErrorDataIntegration(),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    tracePropagationTargets: [
      // TODO: add production domains
      "localhost",
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}
ReactDOM.createRoot(document.getElementById("root")!).render(
  <MantineProvider theme={theme}>
    <Notifications />
    <DatesProvider settings={{ locale: "en" }}>
      <ModalsProvider>
        <App />
      </ModalsProvider>
    </DatesProvider>
  </MantineProvider>,
)
