import { Combobox, Loader, TextInput, useCombobox } from "@mantine/core"
import { useRef, useState } from "react"

import { AxesResponses } from "@costory/types/endpoints/axes"

import { apiClient } from "@costory/front/lib/apiClient"

import type { SearchAcrossValuesResponseItem } from "./types"

function getAsyncData(searchQuery: string, signal: AbortSignal) {
  return new Promise<SearchAcrossValuesResponseItem[]>((resolve, reject) => {
    signal.addEventListener("abort", () => {
      reject(new Error("Request aborted"))
    })

    apiClient
      .get<AxesResponses.Axis[]>(`/axes/search/${searchQuery}`)
      .then((response) => {
        resolve(
          response.data.map((item) => {
            return {
              field: item.name,
              value: item.values[0].name,
            }
          }),
        )
      })
  })
}

// Some of the function in combobox can only handle strings, so we use these
// to get around the restriction and return a ResponseItem
const joinResponseItem = (item: SearchAcrossValuesResponseItem) =>
  `${item.field}:${item.value}`
const splitResponseItem = (item: string): SearchAcrossValuesResponseItem => {
  const [field, value] = item.split(":")
  return { field, value }
}

// Based on https://mantine.dev/combobox/?e=AsyncAutocomplete
const SearchAcrossValues = ({
  onChange,
}: {
  onChange: (value: SearchAcrossValuesResponseItem) => void
}) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  })
  const abortController = useRef<AbortController>()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState("")
  const [empty, setEmpty] = useState(false)
  const [data, setData] = useState<SearchAcrossValuesResponseItem[] | null>(
    null,
  )

  const fetchOptions = (query: string) => {
    abortController.current?.abort()
    abortController.current = new AbortController()
    setLoading(true)

    getAsyncData(query, abortController.current.signal)
      .then((result) => {
        setData(result)
        setLoading(false)
        setEmpty(result.length === 0)
        abortController.current = undefined
      })
      .catch(() => {})
  }

  const options = (data || []).map((item) => (
    <Combobox.Option
      value={joinResponseItem(item)}
      key={joinResponseItem(item)}
    >
      {`${item.field}:${item.value}`}
    </Combobox.Option>
  ))

  return (
    <Combobox
      onOptionSubmit={(optionValue) => {
        onChange(splitResponseItem(optionValue))
        setValue(optionValue)
        combobox.closeDropdown()
      }}
      withinPortal={false}
      store={combobox}
    >
      <Combobox.Target>
        <TextInput
          label="Type a value"
          placeholder="Search across values"
          value={value}
          onChange={(event) => {
            setValue(event.currentTarget.value)
            fetchOptions(event.currentTarget.value)
            combobox.resetSelectedOption()
            combobox.openDropdown()
          }}
          onClick={() => combobox.openDropdown()}
          onFocus={(e) => {
            combobox.openDropdown()
            if (data === null && e.target.value.trim() != "") {
              fetchOptions(value)
            }
          }}
          onBlur={() => combobox.closeDropdown()}
          rightSection={loading && <Loader size={18} />}
          style={{ flexGrow: "1" }}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={data === null}>
        <Combobox.Options>
          {options}
          {empty && <Combobox.Empty>No values found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default SearchAcrossValues
