import { Button, Flex, Modal } from "@mantine/core"
import { useState } from "react"

import SearchAcrossValues from "./SearchAcrossValues"
import type {
  SearchAcrossValuesContext,
  SearchAcrossValuesResponseItem,
} from "./types"

interface Props {
  opened: boolean
  onClose: () => void
  onSubmit: (
    e: React.MouseEvent<HTMLButtonElement>,
    context: SearchAcrossValuesContext,
  ) => void
}

function SmartQueryBuilderModal({ opened, onClose, onSubmit }: Props) {
  const [value, setValue] = useState<SearchAcrossValuesResponseItem | null>(
    null,
  )

  const onChange = (item: SearchAcrossValuesResponseItem) => {
    setValue(item)
  }

  return (
    <Modal
      styles={{
        // Let dropdowns expand outside the modal:
        content: { overflowY: "visible" },
      }}
      opened={opened}
      onClose={onClose}
      size="lg"
      title="Smart Query Builder"
      centered
    >
      <Flex justify="space-between" align="end" gap="md">
        <SearchAcrossValues onChange={onChange} />

        <Button
          onClick={(e) => {
            onSubmit(e, value as SearchAcrossValuesContext)
          }}
        >
          Add Rule
        </Button>
      </Flex>
    </Modal>
  )
}

export default SmartQueryBuilderModal
