/**
 * Never import it!
 * Prefer using `const { colors } = useMantineTheme()`
 */
const gray = [
  "#F8FAFC",
  "#E2E8F0",
  "#CBD5E1",
  "#94A3B8",
  "#64748B",
  "#475569",
  "#334155",
  "#1E293B",
  "#182235",
  "#0F172A",
  "#020617",
]

export const colors = {
  primary: [
    "#EEF2FF",
    "#E0E7FF",
    "#C7D2FE",
    "#A5B4FC",
    "#818CF8",
    "#6366F1",
    "#4F46E5",
    "#4338CA",
    "#3730A3",
    "#312E81",
    "#1E1B4B",
  ],
  green: [
    "#ECFDF5",
    "#D1FAE5",
    "#A7F3D0",
    "#6EE7B7",
    "#34D399",
    "#10B981",
    "#059669",
    "#047857",
    "#065F46",
    "#064E3B",
    "#022C22",
  ],
  red: [
    "#FEF2F2",
    "#FEE2E2",
    "#FECACA",
    "#FCA5A5",
    "#F87171",
    "#EF4444",
    "#DC2626",
    "#B91C1C",
    "#991B1B",
    "#7F1D1D",
    "#450A0A",
  ],
  gray,
  // Used for dark theme
  // TODO: We should probably use `virtualColor` to have a consistent light/dark theme but Mantine doc is unclear on how to do it
  //  See: https://mantine.dev/theming/colors/#color-prop
  dark: gray,
} as const

export type Color = keyof typeof colors
