import { HTMLProps } from "react"

import { Td } from "@costory/front/pages/Budgets/Td"

interface DataCellProps extends HTMLProps<HTMLTableCellElement> {
  data: string
}

export const DataCell = function DataCell({
  data,
  style,
  ...props
}: DataCellProps) {
  return (
    <Td
      {...props}
      style={{ color: "var(--mantine-color-gray-7)", ...style }}
      ref={null}
    >
      {data}
    </Td>
  )
}
