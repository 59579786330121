import { Divider, Flex, Group, Stack, Text } from "@mantine/core"

export const FileExplorerHeader = () => (
  <Stack>
    <Flex
      pos="sticky"
      miw={500}
      mr={32}
      mt={16}
      styles={{ root: { flexDirection: "row-reverse" } }}
    >
      <Group w={600} justify="space-around">
        <Text fw="bold" flex={4} ta="center">
          Tags
        </Text>
        <Text fw="bold" flex={3} ta="center">
          Last edited
        </Text>
        <Text fw="bold" flex={3} ta="center">
          Owner
        </Text>
        <Text fw="bold" flex={1} ta="center">
          Privacy
        </Text>
      </Group>
      <Group flex={1} ml={32}>
        <Text fw="bold">Name</Text>
      </Group>
    </Flex>
    <Divider color="gray.1" />
  </Stack>
)
