import { Modal } from "@mantine/core"

import {
  DatasourcesRequests,
  DatasourcesResponses,
} from "@costory/types/endpoints/datasources"

import { DatasourceForm } from "@costory/front/pages/Datasources/components/DatasourceForm"

type Props = {
  datasource?: DatasourcesResponses.Datasource
  onSave: (datasource: DatasourcesRequests.EditDatasource) => void
  isSaving: boolean
  opened: boolean
  onClose: () => void
}
export const EditCreateModal = ({
  datasource,
  onSave,
  isSaving,
  opened,
  onClose,
}: Props) => {
  return (
    <Modal
      size="xl"
      opened={opened}
      onClose={onClose}
      title={datasource ? "Edit Datasource" : "Create Datasource"}
    >
      <DatasourceForm
        datasource={datasource}
        onSave={onSave}
        isSaving={isSaving}
      />
    </Modal>
  )
}
