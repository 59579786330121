import { Group, Paper, Stack } from "@mantine/core"
import { useParams } from "react-router-dom"

import { SavedViewsResponses } from "@costory/types/endpoints/savedViews"

import { ChartContainer } from "@costory/front/components/ChartContainer"
import { DonutChart } from "@costory/front/components/charts/DonutChart"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { ExplorerMainChart } from "@costory/front/pages/Explorer/ExplorerMainChart"
import { ExplorerTable } from "@costory/front/pages/Explorer/ExplorerTable"
import { useSavedViewQuery } from "@costory/front/queries/savedViews"
import { getBestDrillDown } from "@costory/front/utils/columns"

type Props = {
  savedView?: SavedViewsResponses.SavedView
}

const Explorer = ({ savedView }: Props) => {
  return (
    <ChartContainer
      savedView={savedView}
      redirectPage="explorer"
      key={savedView ? savedView.id : "no-view"}
    >
      {({ filters, drillDownInto, setExplorerChartType }) => {
        const { explorerChartType, ...explorerFilters } = filters
        return (
          <Stack>
            <Group flex={1} align="stretch" h={400}>
              <Paper flex={1} px={32} py={24}>
                <Stack h={400} justify="center">
                  <ExplorerMainChart
                    explorerChartType={explorerChartType}
                    filters={explorerFilters}
                    drillDownInto={drillDownInto}
                    setExplorerChartType={setExplorerChartType}
                  />
                </Stack>
              </Paper>
              <Paper w={350} px={32} py={24}>
                <Stack h="none" align="center" justify="center">
                  <DonutChart
                    filters={explorerFilters}
                    height={300}
                    displayTrend={true}
                    drillDownInto={(value) =>
                      drillDownInto(
                        explorerFilters.groupBy,
                        value,
                        getBestDrillDown(explorerFilters.groupBy),
                      )
                    }
                  />
                </Stack>
              </Paper>
            </Group>
            <ExplorerTable
              filters={explorerFilters}
              drillDownInto={drillDownInto}
            />
          </Stack>
        )
      }}
    </ChartContainer>
  )
}
export const ExplorerPage = () => {
  const { viewId } = useParams()
  const savedViewQuery = useSavedViewQuery(viewId)

  if (viewId) {
    return (
      <QueryWrapper query={savedViewQuery}>
        {({ data: savedView }) => <Explorer savedView={savedView} />}
      </QueryWrapper>
    )
  }

  return <Explorer />
}
