export const Tr = (props: React.HTMLProps<HTMLTableRowElement>) => (
  <tr
    {...props}
    style={{
      borderBottom: "1px solid var(--mantine-color-gray-3)",
      ...props.style,
    }}
  >
    {props.children}
  </tr>
)
