import { Button, Group, NumberInput, Stack, Text, Tooltip } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { showNotification } from "@mantine/notifications"
import {
  IconAlertTriangleFilled,
  IconMathMax,
  IconSettingsSpark,
} from "@tabler/icons-react"
import { FC } from "react"

import { ExploreResponses } from "@costory/types/endpoints/explorer"
import { Filters } from "@costory/types/filters"
import { AlertPeriod } from "@costory/types/prisma-client"

import { AlertFormData } from "@costory/front/pages/Alerts/AlertForm"
import { AlertLevels } from "@costory/front/pages/Alerts/types"
import { useValueSuggestionQuery } from "@costory/front/queries/alerts"
import { formatNumber } from "@costory/front/utils/format"
import { percentile } from "@costory/front/utils/percentile"

const getPercentile = (level: AlertLevels) => {
  switch (level) {
    case AlertLevels.Red:
      return 95
    case AlertLevels.Orange:
      return 80
    case AlertLevels.Yellow:
      return 60
  }
}

interface LimitInputCardProps {
  filters: Filters | undefined
  level: AlertLevels
  form: UseFormReturnType<AlertFormData>
  onClickSuggestValue: (suggestedValue: number) => void
}

export const LimitInputCard: FC<LimitInputCardProps> = ({
  filters,
  form,
  level,
  onClickSuggestValue,
}) => {
  const { data, isSuccess, isLoading } = useValueSuggestionQuery(filters)
  const inputProps = form.getInputProps(`${level.toLowerCase()}Limit`)

  const shouldDisplaySuggestion = !!filters && isSuccess

  const suggestedValue = shouldDisplaySuggestion
    ? percentile(
        data?.totals.map((total) => total.cost) ?? [],
        getPercentile(level),
      )
    : undefined

  /**
   * Returns the number of times the cost over a period is above the limit
   */
  const computeHits = (
    limit: number,
    totals: ExploreResponses.ExplorerData["totals"],
  ) => totals.reduce((acc, total) => acc + (total.cost > limit ? 1 : 0), 0)

  const lastHits =
    shouldDisplaySuggestion && inputProps.value
      ? computeHits(inputProps.value, data!.totals)
      : null

  const getPeriodForm = (period: AlertPeriod) => {
    switch (period) {
      case AlertPeriod.DAILY:
        return "days"
      case AlertPeriod.WEEKLY:
        return "weeks"
      case AlertPeriod.MONTHLY:
        return "months"
    }
  }

  const checkLimitIsInRange = () => {
    if (inputProps.value && isSuccess) {
      const limit = inputProps.value
      const lastValue = data!.totals[0].cost

      if (limit < lastValue * 0.7 || limit > lastValue * 1.3) {
        showNotification({
          title: (
            <Group gap={2}>
              <IconAlertTriangleFilled color="orange" size={20} />
              Warning
            </Group>
          ),
          message: `The ${level.toLowerCase()} limit you've set differs by more than 30% from the most recent calculated cost`,
          color: "orange",
          autoClose: 3000,
        })
      }
    }
  }

  return (
    <Stack flex={1} gap={0}>
      <Group align="flex-start">
        <Stack align="stretch" flex={1} gap={2}>
          <Group justify="space-between">
            <Group gap={4} align="space-between" flex={1}>
              <IconMathMax size={20} color={level.toLowerCase()} />
              <Text fw="bold">{level} limit</Text>
            </Group>
            {lastHits !== null && (
              <Text color="gray.5">
                This alert would have triggered {lastHits} times over the last{" "}
                {data?.totals.length} {getPeriodForm(form.values.period)}
              </Text>
            )}
          </Group>
          <NumberInput
            {...inputProps}
            onBlur={checkLimitIsInRange}
            step={100}
            placeholder="Ex: 3000"
            allowDecimal={false}
            allowNegative={false}
            flex={1}
          />
        </Stack>
        <Tooltip
          label={
            shouldDisplaySuggestion
              ? `Suggested value: ${formatNumber(suggestedValue!, "currency", undefined, filters.currency)}`
              : "Make sure all above fields are filled"
          }
        >
          <Button
            mt={22}
            size="sm"
            leftSection={<IconSettingsSpark />}
            disabled={!shouldDisplaySuggestion}
            loading={isLoading}
            onClick={() => onClickSuggestValue(suggestedValue!)}
          >
            CostorySuggest
          </Button>
        </Tooltip>
      </Group>
    </Stack>
  )
}
