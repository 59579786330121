import { useParams } from "react-router-dom"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { VirtualDimensionForm } from "@costory/front/pages/VirtualDimensions/components/VirtualDimensionForm"
import {
  useCreateDraftVirtualDimensionMutation,
  useUpdateVirtualDimensionMutation,
  useVirtualDimensionDraftQuery,
} from "@costory/front/queries/virtualDimensions"

export const EditVirtualDimensionVersion = () => {
  const params = useParams()
  const draftId = params.draftId as string
  const virtualDimensionQuery = useVirtualDimensionDraftQuery(draftId)

  const { mutateAsync: updateVirtualDimension, isPending } =
    useUpdateVirtualDimensionMutation(params.virtualDimensionId as string)
  const {
    mutateAsync: createDraftVirtualDimension,
    isPending: isPendingDraftVirtualDimensions,
  } = useCreateDraftVirtualDimensionMutation()

  return (
    <QueryWrapper query={virtualDimensionQuery}>
      {({ data }) => (
        <VirtualDimensionForm
          inputData={{ data, type: "form" }}
          onSave={updateVirtualDimension}
          isSaving={isPending}
          isSavingDraft={isPendingDraftVirtualDimensions}
          onSaveDraft={createDraftVirtualDimension}
        />
      )}
    </QueryWrapper>
  )
}
