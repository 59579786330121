import {
  ActionIcon,
  Badge,
  Button,
  Group,
  Menu,
  SegmentedControl,
  Stack,
  Title,
  Tree,
} from "@mantine/core"
import { DatePickerInput } from "@mantine/dates"
import { useForm, UseFormReturnType } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import {
  IconAdjustmentsAlt,
  IconChevronDown,
  IconTrash,
} from "@tabler/icons-react"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"

import { AxesResponses } from "@costory/types/endpoints/axes"
import {
  DigestResponses,
  DigestRequests,
} from "@costory/types/endpoints/digest"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"
import { ButtonAddTo } from "@costory/front/pages/Digest/ButtonAddToDigest"
import { Contextualize } from "@costory/front/pages/Digest/Contextualize"
import { ExplorerDrawer } from "@costory/front/pages/Digest/Drawer"
import {
  addChild,
  addContext,
  convertToTree,
  deleteChild,
  detectOldNew,
  Form,
  formatMarkdown,
  formatSlice,
  getOptions,
  getquery,
  NodeInvestigation,
  TreeData,
} from "@costory/front/pages/Digest/helper"
import { useAxesQuery } from "@costory/front/queries/axes"

export const DigestPage = () => {
  const axesQuery = useAxesQuery()
  const [period, setPeriod] = useState<DigestRequests.Period>("MonthToMonth")
  const form = useForm<Form>({
    mode: "uncontrolled",
    initialValues: { nodes: [], labels: [] },
  })
  const updatePeriod = (newPeriod: DigestRequests.Period) => {
    form.reset()
    setPeriod(newPeriod)
  }
  return (
    <QueryWrapper query={axesQuery}>
      {(axes) => (
        <>
          <Stack>
            <Title>Digest Builder (Pre-Alpha): </Title>
            <Stack align="flex-start">
              <Group>
                <SegmentedControl
                  value={period}
                  onChange={(e) => updatePeriod(e as DigestRequests.Period)}
                  data={[
                    { label: "Month To Date change", value: "MTD" },
                    { label: "Month To Month Change", value: "MonthToMonth" },
                    { label: "Absolute Last Month", value: "Absolute" },
                  ]}
                />
                <Button onClick={() => form.reset()}> Reset Digest </Button>
              </Group>
              <_DigestPage period={period} axes={axes.data} form={form} />
            </Stack>
          </Stack>
        </>
      )}
    </QueryWrapper>
  )
}

function _DigestPage({
  axes,
  period,
  form,
}: {
  axes: AxesResponses.Axis[]
  form: UseFormReturnType<Form>
  period: DigestRequests.Period
}) {
  const query = useQuery({
    queryKey: ["digest", period],
    queryFn: async () => {
      const response = await apiClient.get<DigestResponses.Investigation>(
        `/digest/${period}`,
      )
      return response.data
    },
  })

  const mapper = new Map<string, string>()
  axes.forEach((elem) => mapper.set(elem.name, elem.label))
  const [opened, handlers] = useDisclosure(false)
  const [position, setPosition] = useState<{ key: string; value: string }[]>([])
  return (
    <QueryWrapper query={query}>
      {(data) => (
        <>
          <ExplorerDrawer
            period={period}
            opened={opened}
            close={handlers.close}
            position={position}
          />
          <Group justify="flex-end" align="end">
            <DatePickerInput
              type="range"
              disabled
              label="Comparison Period"
              placeholder="Pick dates range"
              value={[
                new Date(data.data[0].previousFrom),
                new Date(data.data[0].previousTo),
              ]}
            />
            <DatePickerInput
              type="range"
              disabled
              label="Period"
              placeholder="Pick dates range"
              value={[new Date(data.data[0].ffrom), new Date(data.data[0].tto)]}
            />
            <Button
              onClick={() =>
                navigator.clipboard.writeText(
                  formatMarkdown(form, mapper, data.data[0].ffrom).join("\n"),
                )
              }
            >
              Copy report as Markdown
            </Button>
          </Group>

          <ButtonAddTo
            disabled={false}
            mapper={mapper}
            options={getOptions(data.data, [])}
            addChild={(direction) => {
              const baseNode = {
                uuid: Math.random().toString(),
                parent: undefined,
                isOther: false,
                direction: "View Per Provider",
                position: [],
                displayOptions: {
                  text: "",
                  displayContext: true,
                  order: "asc",
                  orderByColumn: "absChange",
                },
                data: { diff: 0 },
              } as NodeInvestigation
              form.insertListItem("nodes", baseNode)
              addChild(form, direction, data.data, baseNode.uuid)
            }}
          />
          <Tree
            data={convertToTree(form.getValues().nodes)}
            renderNode={({
              node,
              expanded,
              hasChildren,
              elementProps,
              tree,
            }) => {
              const nodeParsed = node.nodeProps as NodeInvestigation
              return (
                <Group
                  gap={5}
                  style={elementProps.style}
                  className={elementProps.className}
                >
                  {formatSlice(nodeParsed, nodeParsed.direction, mapper, true)}
                  {detectOldNew(nodeParsed, data.data[0].ffrom)
                    .conditionDropped && <Badge color="cyan"> Dropped </Badge>}
                  {detectOldNew(nodeParsed, data.data[0].ffrom)
                    .conditionNew && <Badge color="orange"> New </Badge>}
                  <Contextualize
                    node={nodeParsed}
                    store={(val) =>
                      addContext(form, nodeParsed.uuid, { text: val })
                    }
                  />
                  <Menu shadow="md" width={200}>
                    <Menu.Target>
                      <ActionIcon
                        variant="transparent"
                        aria-label="Settings"
                        disabled={nodeParsed.isOther}
                      >
                        <IconAdjustmentsAlt
                          style={{ width: "70%", height: "70%" }}
                          stroke={1.5}
                        />
                      </ActionIcon>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Label>Explore</Menu.Label>
                      <Menu.Item
                        disabled={nodeParsed.position.length == 0}
                        onClick={() => {
                          setPosition(nodeParsed.position)
                          handlers.open()
                        }}
                      >
                        See in Explorer Drawer
                      </Menu.Item>
                      <Menu.Item
                        disabled={node.nodeProps?.position.length == 0}
                        onClick={() =>
                          window.open(
                            `/explorer?${getquery(node as TreeData).queryFormatted}`,
                            "_blank",
                          )
                        }
                      >
                        See in Explorer
                      </Menu.Item>
                      <Menu.Label>Contextualize</Menu.Label>
                      <Menu.Item
                        onClick={() =>
                          addContext(form, nodeParsed.uuid, {
                            displayContext:
                              !nodeParsed.displayOptions.displayContext,
                          })
                        }
                      >
                        Adds previous and current absolute value.
                      </Menu.Item>
                      <Menu.Item
                        onClick={() =>
                          addContext(form, nodeParsed.uuid, {
                            orderByColumn: "cost",
                            order: "desc",
                          })
                        }
                      >
                        Order by absolute cost desc
                      </Menu.Item>
                      <Menu.Item
                        onClick={() =>
                          addContext(form, nodeParsed.uuid, {
                            orderByColumn: "absChange",
                            order: "desc",
                          })
                        }
                      >
                        Order by absolute change desc
                      </Menu.Item>
                      <Menu.Item
                        onClick={() =>
                          addContext(form, nodeParsed.uuid, {
                            orderByColumn: "relativeChange",
                            order: "desc",
                          })
                        }
                      >
                        Order by relative change desc
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                  <ActionIcon
                    variant="transparent"
                    aria-label="Settings"
                    onClick={() => deleteChild(nodeParsed.uuid, form)}
                    disabled={nodeParsed.isOther}
                  >
                    <IconTrash
                      style={{ width: "70%", height: "70%" }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                  <ButtonAddTo
                    disabled={nodeParsed.position.length === 0}
                    total={nodeParsed.data.diff}
                    mapper={mapper}
                    options={getOptions(data.data, nodeParsed.position)}
                    addChild={(direction) =>
                      addChild(form, direction, data.data, nodeParsed.uuid)
                    }
                  />
                  {hasChildren && (
                    <>
                      <IconChevronDown
                        onClick={() => tree.toggleExpanded(node.value)}
                        size={18}
                        style={{
                          transform: expanded
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      />
                    </>
                  )}
                </Group>
              )
            }}
          />
        </>
      )}
    </QueryWrapper>
  )
}
