import { Sparkline } from "@mantine/charts"
import { ActionIcon, Stack, Text, Title } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconZoom } from "@tabler/icons-react"
import { useQuery } from "@tanstack/react-query"
import _ from "lodash"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo, useState } from "react"

import { DataHealthResponses } from "@costory/types/endpoints/dataHealth"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { DataHealthStatusBadgeCell } from "@costory/front/components/table/DataHealthStatusBadgeCell"
import { ModalDetails } from "@costory/front/components/table/ModalDetails"
import { apiClient } from "@costory/front/lib/apiClient"
import { getDefaultTableOptions } from "@costory/front/utils/table"

type Props = {
  dataHealth: DataHealthResponses.DataHealthStatus[]
}

const DataHealth = ({ dataHealth }: Props) => {
  function onZoom(row: DataHealthResponses.DataHealthStatus) {
    setSelectedRow(row)
    open()
  }
  const [opened, { open, close }] = useDisclosure(false)
  const [selectedRow, setSelectedRow] =
    useState<DataHealthResponses.DataHealthStatus>()
  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<DataHealthResponses.DataHealthStatus>()
    return [
      columnHelper.accessor("datasource", {
        header: "Datasource",
        filterVariant: "select",
      }),
      columnHelper.accessor("status", {
        header: "Status",
        filterVariant: "select",
        Cell: ({ cell }) => (
          <DataHealthStatusBadgeCell status={cell.getValue()} />
        ),
      }),
      columnHelper.accessor("minIngestedUsageDate", {
        header: "Min Usage Date Ingested",
      }),
      columnHelper.accessor("maxIngestedUsageDate", {
        header: "Max Usage Date Ingested",
      }),
      columnHelper.accessor("costLastDays", {
        header: "Cost of Last 100 days",
        enableSorting: false,

        Cell: ({ cell }) => (
          <Sparkline
            w={200}
            h={40}
            data={_.map(cell.getValue(), (i) => i.cost)}
            curveType="linear"
            color="primary"
            //fillOpacity={0.6}
            //strokeWidth={2}
          />
        ),
      }),
    ]
  }, [])

  const table = useMantineReactTable<DataHealthResponses.DataHealthStatus>({
    columns,
    data: dataHealth,
    enableRowActions: true,
    positionActionsColumn: "last",
    renderRowActions: ({ row }) => (
      <ActionIcon onClick={() => onZoom(row.original)}>
        <IconZoom />
      </ActionIcon>
    ),
    ...getDefaultTableOptions(),
  })

  return (
    <Stack>
      <Stack gap={2}>
        <Title>Data Health Status</Title>
        <Text>Overview of the health of the various datasources</Text>
      </Stack>

      <MantineReactTable table={table} />
      <ModalDetails opened={opened} onClose={close} data={selectedRow} />
    </Stack>
  )
}

export const DataHealthPage = () => {
  const dateHealthQuery = useQuery({
    queryKey: ["dataHealth"],
    queryFn: async () => {
      const response =
        await apiClient.get<DataHealthResponses.DataHealthStatus[]>(
          "/data-health",
        )
      return response.data
    },
  })

  return (
    <QueryWrapper query={dateHealthQuery}>
      {({ data }) => <DataHealth dataHealth={data} />}
    </QueryWrapper>
  )
}
