import {
  Group,
  InputWrapper,
  Loader,
  Paper,
  Select,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core"
import _ from "lodash"
import {
  createMRTColumnHelper,
  MRT_Table,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo, useState } from "react"

import { DatasourcesResponses } from "@costory/types/endpoints/datasources"

import { SelectCreatable } from "@costory/front/components/SelectCreatable"
import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import {
  useDatasourceBusinessMetricsValuesQuery,
  useTelemetryOptionsQuery,
} from "@costory/front/queries/datasources"
import { formatNumber } from "@costory/front/utils/format"
import { PropsWithData } from "@costory/front/utils/propsWithData"

type _AutoSplitTableProps =
  PropsWithData<DatasourcesResponses.GetTelemetryOptions> & AutoSplitTableProps

const _AutoSplitTable = ({
  data,
  ruleCost,
  availableDimensionValues,
  onChange,
  onSelectDataSource,
  onCreate,
  values,
}: _AutoSplitTableProps) => {
  const [selectedDatasourceId, setSelectedDatasourceId] = useState<
    string | null
  >(null)
  const datasourceSelectItems = data.map(({ id, name, businessMetrics }) => ({
    value: id,
    label: name,
    disabled: businessMetrics.length < 2,
  }))

  const selectedDataSource = useMemo(
    () => data.find(({ id }) => id === selectedDatasourceId),
    [data, selectedDatasourceId],
  )

  const handleChangeDatasource = (value: string | null) => {
    setSelectedDatasourceId(value)
    onSelectDataSource(
      value ?? "",
      data
        .find(({ id }) => id === value)
        ?.businessMetrics.map(({ id }) => id) ?? [],
    )
  }

  const Table = ({ datasourceId }: { datasourceId: string | undefined }) => {
    const { data: businessMetricsValues, isPending } =
      useDatasourceBusinessMetricsValuesQuery(datasourceId)

    const totalValue = businessMetricsValues
      ? _.sum(Object.values(businessMetricsValues))
      : null

    const tableData =
      selectedDataSource?.businessMetrics.map(({ id, name }) => ({
        id,
        name,
        value: businessMetricsValues ? businessMetricsValues[id] : null,
      })) ?? []

    const columnHelper = createMRTColumnHelper<(typeof tableData)[number]>()
    const table = useMantineReactTable({
      columns: [
        columnHelper.accessor("name", {
          header: "Name",
        }),
        columnHelper.display({
          header: "Percentage (AVG last 30 days)",
          Cell: ({
            row: {
              original: { value },
            },
          }) =>
            isPending ? (
              <Loader size="sm" />
            ) : (
              <Text>
                {totalValue && value !== null
                  ? ((value / totalValue) * 100).toFixed(2)
                  : "-"}
                %
              </Text>
            ),
        }),
        columnHelper.display({
          header: "Assigned dimension value",
          Cell: ({
            row: {
              original: { id },
            },
          }) => (
            <SelectCreatable
              withinPortal
              data={availableDimensionValues}
              value={values[id]}
              onChange={(virtualDimensionValue) =>
                onChange({ businessMetricId: id, virtualDimensionValue })
              }
              handleCreate={onCreate}
              error={undefined}
            />
          ),
        }),
        columnHelper.display({
          header: "Approximated cost (Average cost * average percentage)",
          Cell: ({
            row: {
              original: { value },
            },
          }) => (
            <Text>
              {totalValue && value !== null
                ? formatNumber((value / totalValue) * ruleCost)
                : "-"}
            </Text>
          ),
        }),
      ],
      data: tableData,
    })

    return <MRT_Table table={table} />
  }

  return (
    <Paper>
      <Stack align="stretch">
        <Group>
          <InputWrapper label="Datasource">
            <Select
              placeholder="Select a datasource"
              data={datasourceSelectItems}
              onChange={handleChangeDatasource}
              renderOption={({ option: { label, disabled } }) => (
                <Tooltip
                  label={disabled ? "Not enough business metrics" : ""}
                  disabled={!disabled}
                >
                  <Group flex={1}>
                    <Text>{label}</Text>
                  </Group>
                </Tooltip>
              )}
            />
          </InputWrapper>
          <Table datasourceId={selectedDatasourceId ?? ""} />
        </Group>
      </Stack>
    </Paper>
  )
}

interface AutoSplitTableProps {
  ruleCost: number
  availableDimensionValues: string[]
  values: Record<string, string>
  onSelectDataSource: (
    datasourceId: string,
    businessMetricIds: string[],
  ) => void
  onChange: ({
    businessMetricId,
    virtualDimensionValue,
  }: {
    businessMetricId: string
    virtualDimensionValue: string
  }) => void
  onCreate: (value: string) => void
}

export const AutoSplitTable = (props: AutoSplitTableProps) => {
  const query = useTelemetryOptionsQuery()
  return (
    <QueryWrapper query={query}>
      {({ data: telemetryOptions }) => (
        <_AutoSplitTable data={telemetryOptions} {...props} />
      )}
    </QueryWrapper>
  )
}
