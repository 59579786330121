import { Stack } from "@mantine/core"
import {
  createMRTColumnHelper,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table"
import { useMemo } from "react"

import { LabelsExplorerResponses } from "@costory/types/endpoints/labelsExplorer"

import { NumberCell } from "@costory/front/components/table/NumberCell"
import { getDefaultTableOptions } from "@costory/front/utils/table"

type Props = {
  lableValues: LabelsExplorerResponses.Values[]
}

export const LabelsExplorerValuesTable = ({ lableValues }: Props) => {
  const columns = useMemo(() => {
    const columnHelper = createMRTColumnHelper<LabelsExplorerResponses.Values>()
    return [
      columnHelper.accessor("value", {
        header: "Label",
      }),

      columnHelper.accessor("proportionCostAllocated", {
        header: "Percentage Allocated",
        Cell: ({ cell }) => {
          const value = +cell.getValue()! || 0
          return (
            <NumberCell
              value={value}
              numberType="percent"
              disableColor={true}
              currency="USD"
            />
          )
        },
      }),
    ]
  }, [])

  const table = useMantineReactTable<LabelsExplorerResponses.Values>({
    columns,
    data: lableValues as LabelsExplorerResponses.Values[],
    ...getDefaultTableOptions(true),
  })

  return (
    <Stack>
      <MantineReactTable table={table} />
    </Stack>
  )
}
