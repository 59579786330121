import { forwardRef } from "react"

export type TdProps = React.HTMLProps<HTMLTableCellElement>

export const Td = forwardRef<HTMLTableCellElement, TdProps>(function _Td(
  props: TdProps,
  ref,
) {
  return (
    <td
      {...props}
      style={{
        textAlign: "center",
        borderBottom: "1px solid var(--mantine-color-gray-3)",
        ...props.style,
      }}
      ref={ref}
    >
      {props.children}
    </td>
  )
})
