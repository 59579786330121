import { useMantineTheme } from "@mantine/core"
import { useFocusWithin, useHover, useMergedRef } from "@mantine/hooks"

import { Td } from "@costory/front/pages/Budgets/Td"

export const EditableTd = ({
  children,
  ...props
}: React.HTMLProps<HTMLTableCellElement>) => {
  const { ref: focusRef, focused } = useFocusWithin<HTMLTableCellElement>()
  const { ref: hoverRef, hovered } = useHover<HTMLTableCellElement>()
  const ref = useMergedRef<HTMLTableCellElement>(focusRef, hoverRef)
  const { colors } = useMantineTheme()

  return (
    <Td
      {...props}
      ref={ref}
      style={{
        borderRight: "none",
        borderBottomStyle: "solid",
        borderBottomWidth: 4,
        borderBottomColor: focused
          ? colors.indigo[4]
          : hovered
            ? colors.indigo[2]
            : colors.gray[2],
        backgroundColor: "var(--mantine-color-primary-1)",
        ...props.style,
      }}
    >
      {children}
    </Td>
  )
}
