import { useAuth, useUser as useClerkUser } from "@clerk/clerk-react"
import { useQuery } from "@tanstack/react-query"

import { AuthResponses } from "@costory/types/endpoints/auth"
import { User } from "@costory/types/user"

import { apiClient } from "@costory/front/lib/apiClient"

type AuthState =
  | {
      isLoading: true
      isSignedIn: undefined
      user: undefined
      isError: boolean
    }
  | {
      isLoading: false
      isSignedIn: false
      user: undefined
      isError: boolean
    }
  | {
      isLoading: false
      isSignedIn: true
      user: User
      isError: false
    }

export const useAuthState = (): AuthState => {
  const { isLoaded, isSignedIn, user: clerkUser } = useClerkUser()
  const { orgRole } = useAuth()

  const { data: user, isLoading: isMeLoading } = useQuery<
    User | undefined | null
  >({
    queryFn: async () => {
      if (!isLoaded || !isSignedIn) return undefined
      try {
        const response = await apiClient.get<AuthResponses.Me>("/auth/me")
        return response.data
      } catch (error) {
        return null
      }
    },
    queryKey: ["me", clerkUser?.id],
    enabled: isLoaded && isSignedIn,
  })

  const isLoading = !isLoaded || isMeLoading
  if (isLoading)
    return {
      isLoading: true,
      isSignedIn: undefined,
      user: undefined,
      isError: false,
    }
  if (!isSignedIn || !user) {
    return {
      isLoading: false,
      isSignedIn: false,
      user: undefined,
      isError: true,
    }
  }
  return {
    isLoading,
    isSignedIn: true,
    user: {
      ...user,
      isAdmin: orgRole === "admin",
    },
    isError: false,
  }
}
