import { Group, Modal, Title } from "@mantine/core"
import { useQuery } from "@tanstack/react-query"
import { createMRTColumnHelper, MantineReactTable } from "mantine-react-table"
import { useMemo } from "react"

import { ExploreResponses } from "@costory/types/endpoints/explorer"

import { QueryWrapper } from "@costory/front/components/layout/QueryWrapper"
import { apiClient } from "@costory/front/lib/apiClient"

export const ModalCurrency = ({
  opened,
  close,
}: {
  opened: boolean
  close: () => void
}) => {
  const query = useQuery({
    queryKey: ["getCurrency"],
    queryFn: async () => {
      const response =
        await apiClient.get<ExploreResponses.CurrencyData>("/explore/currency")
      return response.data.data
    },
  })
  const columns = useMemo(() => {
    const columnHelper =
      createMRTColumnHelper<ExploreResponses.CurrencyData["data"][0]>()

    return [
      columnHelper.accessor("begMonthDate", {
        header: "Date",
      }),
      columnHelper.accessor("usd_eur", {
        header: "USD->Eur Rate",
      }),
      columnHelper.accessor("AWS", {
        header: "AWS provided rate",
      }),
      columnHelper.accessor("GCP", {
        header: "GCP provided rate",
      }),
      columnHelper.accessor("DataDog", {
        header: "DataDog provided rate",
      }),
      columnHelper.accessor("Azure", {
        header: "Azure provided rate",
      }),
    ]
  }, [])
  return (
    <Modal opened={opened} onClose={close} size="auto">
      <QueryWrapper query={query}>
        {(data) => (
          <>
            <Group>
              <Title size="h3">
                Currency rates used if not provided by the provider{" "}
              </Title>

              <MantineReactTable columns={columns} data={data.data} />
            </Group>
          </>
        )}
      </QueryWrapper>
    </Modal>
  )
}
