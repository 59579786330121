import { Button, Stack, Title } from "@mantine/core"
import { IconBellOff, IconPlus } from "@tabler/icons-react"
import { Link } from "react-router-dom"

// component to show when the data neede for AlertForm is empty
export const NoAlertsPage = () => {
  return (
    <Stack h="70vh" justify="center" align="center">
      <IconBellOff size={56} />
      <Title>No alert found</Title>
      <Button component={Link} to="/alerts/new" leftSection={<IconPlus />}>
        Create an alert
      </Button>
    </Stack>
  )
}
