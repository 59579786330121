import { ActionIcon, Button, Tooltip } from "@mantine/core"
import { showNotification } from "@mantine/notifications"
import { IconDownload } from "@tabler/icons-react"

import { useGetBudgetCSVExportQuery } from "@costory/front/queries/budgets"
import { downloadBlob } from "@costory/front/utils/downloadBlob"

interface BudgetCSVExportDownloadButtonProps {
  budgetId: string
  variant?: "compact" | "default"
}

export const BudgetCSVExportDownloadButton = ({
  budgetId,
  variant = "default",
}: BudgetCSVExportDownloadButtonProps) => {
  const getBudgetCsvExportQuery = useGetBudgetCSVExportQuery(budgetId)

  const handleDownloadCSVExport = async () => {
    const csvExport = await getBudgetCsvExportQuery.refetch()

    if (csvExport.status !== "success") {
      showNotification({
        title: "Error",
        message: "Failed to download CSV export",
        color: "red",
      })
      return
    }

    downloadBlob(csvExport.data, "budget.csv")
  }

  return (
    <Tooltip label="Download CSV export">
      {variant === "compact" ? (
        <ActionIcon
          onClick={handleDownloadCSVExport}
          size="sm"
          loading={getBudgetCsvExportQuery.isLoading}
        >
          <IconDownload size="inherit" />
        </ActionIcon>
      ) : (
        <Button
          variant="subtle"
          onClick={handleDownloadCSVExport}
          leftSection={<IconDownload />}
          loading={getBudgetCsvExportQuery.isLoading}
        >
          Export to CSV
        </Button>
      )}
    </Tooltip>
  )
}
